import { PureComponent } from "react";
import { withGenericConfig } from "/contexts/generic-config-context";
import PictureWithLinkComponent from "components/core/picture-with-link/picture-with-link-component";
import SecondaryLinkItemComponent from "segments/desktop/header/layouts/base-header/secondary-header/secondary-link-item/secondary-link-item-component";
import { getMiniSiteLogo, getMiniSiteLinks } from "client/services/cluster-config-service/cluster-config-service";
import { isSameUrlWithoutQueryParams } from "utils/url";
import { withRouter } from "next/router";
import { componentStyles } from "./secondary-header-desktop-component.scss";

class SecondaryHeaderDesktopComponent extends PureComponent {
  renderLogo = () => {
    const { pageClusterConfig, useProductMiniSiteConfig } = this.props;
    const logo = getMiniSiteLogo(pageClusterConfig, { useProductMiniSiteConfig });

    return (
      <PictureWithLinkComponent
        className="secondary-header-logo-image"
        src={logo?.src}
        alt={logo?.alt}
        href={logo?.url}
      />
    );
  };

  renderLinks = () => {
    const { pageClusterConfig, router, useProductMiniSiteConfig } = this.props;
    const links = getMiniSiteLinks(pageClusterConfig, { useProductMiniSiteConfig });

    return links.map((link, index) => (
      <div className="secondary-link-item-wrapper" key={index}>
        <SecondaryLinkItemComponent
          url={link?.url}
          title={link?.text}
          isSelected={isSameUrlWithoutQueryParams(link?.url, router?.asPath)}
        />
      </div>
    ));
  };

  render() {
    return (
      <nav aria-label="Secondary" className="secondary-header-desktop-component">
        <div className="secondary-header-desktop-content">
          <div className="logo-wrapper">{this.renderLogo()}</div>
          <div className="links-wrapper">{this.renderLinks()}</div>
        </div>
        <style jsx>{componentStyles}</style>
      </nav>
    );
  }
}

export default withRouter(withGenericConfig(SecondaryHeaderDesktopComponent));
