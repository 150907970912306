import css from "styled-jsx/css";

export const componentStyles = css.global`
  .security-logos-component {
    display: flex;

    .iso-logo-img-container,
    .gdpr-logo-img-container,
    .hipaa-logo-img-container,
    .soc-logo-img-container {
      margin-right: 8px;
      cursor: pointer;

      img {
        height: 32px;
      }

      &:hover {
        img {
          opacity: 0.7;
        }
      }
    }
  }
`;
