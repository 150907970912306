import { useCallback, useMemo, useRef } from "react";
import classnames from "classnames";
import ArrowDown from "static/svg/arrow-down.svg";
import { withGenericConfig } from "contexts/generic-config-context";
import BadgeComponent from "components/core/badge/badge-component";
import { generateOnKeyDownHandler, preventFocusOnMouseDown } from "client/services/accessibility/accessibility-service";
import { componentStyles } from "./link-with-menu-component.scss";

const LinkWithSectionsMenuComponent = (props) => {
  const menuEl = useRef(null);
  const { isOpen, title, translate, id, openLinksMenu, links } = props;

  const openMenu = () => {
    const menuId = isOpen ? null : id;
    openLinksMenu(menuId);
  };

  const getStyle = () => {
    // Don't break if menuEl is not yet set or clientHeight is not available for some reason
    // in such case, set height to auto (will disable animation tho)
    const collapseHeight = menuEl?.current?.clientHeight || "auto";

    return isOpen
      ? {
          height: collapseHeight
        }
      : {
          overflow: "hidden",
          height: 0
        };
  };

  const renderSection = ({ section, sectionIndex }) => {
    const { mobileTitle, title, menuSectionItems, hideOnMobile } = section;

    return (
      !hideOnMobile && (
        <div key={`section_${sectionIndex}`} className="section-wrapper">
          <div className="section-link">{translate(mobileTitle || title)}</div>
          <div>
            {menuSectionItems.map((link, linkIndex) => {
              const {
                title: linkTitle,
                url,
                isPopular,
                badgeText,
                badgeColorName,
                badgeTextColorName,
                badgeStrokeColorName,
                openInNewTab,
                mobileIcon: MobileIcon
              } = link;
              return (
                <a
                  key={`link_${linkIndex}`}
                  className="header-link subsection-link"
                  href={url}
                  onMouseDown={preventFocusOnMouseDown}
                  target={openInNewTab ? "_blank" : "_self"}
                >
                  {MobileIcon && <MobileIcon />}
                  {translate(linkTitle)}
                  {isPopular && (
                    <div className="new-badge-component-wrapper">
                      <BadgeComponent
                        badgeText={translate(badgeText)}
                        badgeColorName={badgeColorName}
                        badgeTextColorName={badgeTextColorName}
                        badgeStrokeColorName={badgeStrokeColorName}
                      />
                    </div>
                  )}
                </a>
              );
            })}
          </div>
        </div>
      )
    );
  };

  const onKeyDown = useCallback(generateOnKeyDownHandler(openMenu), [isOpen, id]);
  const style = useMemo(() => getStyle(), [isOpen]);

  return (
    <div className={classnames("link-with-menu-mobile-component", { "is-open": isOpen })}>
      <div
        className="link-with-menu-title-wrapper"
        tabIndex={0}
        onClick={openMenu}
        onKeyDown={onKeyDown}
        onMouseDown={preventFocusOnMouseDown}
        aria-label={translate(title)}
        role="button"
        aria-haspopup="menu"
        aria-expanded={isOpen}
      >
        <span className="link-with-menu-title-text">{translate(title)}</span>
        <ArrowDown className="link-with-menu-title-icon" />
      </div>

      <div className={classnames("link-with-menu-links-wrapper")} style={style}>
        <div ref={menuEl}>
          {links.map((section, sectionIndex) => renderSection({ section, sectionIndex }))}
        </div>
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(LinkWithSectionsMenuComponent);
