import {
  RING_THE_BELL_VIDEO_LINK,
  IPO_BLOG_POST_LINK,
  IPO_BLOG_POST_SPANISH_LINK,
  IPO_BLOG_POST_PORTUGESE_LINK,
  IPO_BLOG_POST_GERMAN_LINK,
  IPO_BLOG_POST_FRENCH_LINK,
  ELEVATE_MAIN_PAGE_LINK
} from "constants/links";
import { SPANISH_LOCALE_ID, FRENCH_LOCALE_ID, GERMAN_LOCALE_ID, PORTUGEESE_LOCALE_ID } from "constants/localization";
import { PURPLE_THEME_ID, GRADIANT_ELEVATE_THEME_ID } from "segments/segments-service";

export const RING_THE_BELL_VALUE = "ring_the_bell";
export const IPO_BLOG_POST_VALUE = "ipo_blog_post";
export const ELEVATE_REGISTRATION_VALUE = "elevate_registration";

export const RING_THE_BELL_TEXT = "Ring the bell";
export const IPO_BLOG_POST_TEXT = "IPO blog post";
export const ELEVATE_REGISTRATION_TEXT = "Elevate Registration";

export const HEADER_POPUP_OPTIONS = [
  { value: RING_THE_BELL_VALUE, text: RING_THE_BELL_TEXT },
  { value: IPO_BLOG_POST_VALUE, text: IPO_BLOG_POST_TEXT },
  { value: ELEVATE_REGISTRATION_VALUE, text: ELEVATE_REGISTRATION_TEXT }
];

const CLOUDINARY_BELL_GIF_SRC =
  "https://dapulse-res.cloudinary.com/image/upload/q_auto/mondayhomepage/ipo/ipo-bell-gif.gif";

const ELEVATE_GIF_SRC = "https://dapulse-res.cloudinary.com/image/upload/q_auto/mondayhomepage/elevate/star-rect.gif";
const ELEVATE_MOBILE_IMAGE_SRC =
  "https://dapulse-res.cloudinary.com/image/upload/f_auto,q_auto/mondayhomepage/elevate/star-static.png";

const getRingTheBellLink = () => {
  return RING_THE_BELL_VIDEO_LINK;
};

const getIpoBlogPostLink = (locale) => {
  switch (locale) {
    case SPANISH_LOCALE_ID:
      return IPO_BLOG_POST_SPANISH_LINK;
    case PORTUGEESE_LOCALE_ID:
      return IPO_BLOG_POST_PORTUGESE_LINK;
    case GERMAN_LOCALE_ID:
      return IPO_BLOG_POST_GERMAN_LINK;
    case FRENCH_LOCALE_ID:
      return IPO_BLOG_POST_FRENCH_LINK;
    default:
      return IPO_BLOG_POST_LINK;
  }
};

const getElevateRegitrationLink = () => {
  return ELEVATE_MAIN_PAGE_LINK;
};

export const HEADER_POPUP_DATA = {
  [RING_THE_BELL_VALUE]: {
    textKey: "header.ipo.popup.ringTheBell.mainText",
    ctaKey: "header.ipo.popup.ringTheBell.cta",
    getLink: getRingTheBellLink,
    bannerTheme: PURPLE_THEME_ID,
    gifSrc: CLOUDINARY_BELL_GIF_SRC
  },
  [IPO_BLOG_POST_VALUE]: {
    textKey: "header.ipo.popup.ipoBlogPost.mainText",
    ctaKey: "header.ipo.popup.ipoBlogPost.cta",
    getLink: getIpoBlogPostLink,
    bannerTheme: PURPLE_THEME_ID,
    gifSrc: CLOUDINARY_BELL_GIF_SRC
  },
  [ELEVATE_REGISTRATION_VALUE]: {
    textKey: "header.elevate.popup.mainText",
    mobileTextKey: "header.elevate.popup.mobileText",
    ctaKey: "header.elevate.popup.cta",
    getLink: getElevateRegitrationLink,
    popupClassName: "elevate",
    bannerTheme: GRADIANT_ELEVATE_THEME_ID,
    gifSrc: ELEVATE_GIF_SRC,
    mobileImageSrc: ELEVATE_MOBILE_IMAGE_SRC
  }
};
