import { useState } from "react";
import classnames from "classnames";
import { componentStyles } from "./structured-header-menu-component.scss";
import StructuredHeaderMenuContentComponent from "./structured-header-menu-content/structured-header-menu-content";
import StructuredHeaderMenuLeftPanelComponent from "./structured-header-menu-left-panel/structured-header-menu-left-panel";
import StructuredHeaderMenuBottomPartComponent from "./structured-header-menu-bottom-part/structured-header-menu-bottom-part";

export const DEAFULT_INNER_MENU_INDEX = 0;
export const DEAFULT_INNER_MENU_SUB_INDEX = 0;

const StructuredHeaderMenuComponent = (props) => {
  const [innerMenuIndex, setInnerMenuIndex] = useState(DEAFULT_INNER_MENU_INDEX);
  const [innerMenuSubIndex, setInnerMenuSubIndex] = useState(DEAFULT_INNER_MENU_SUB_INDEX);
  const [itemsCollapsedState, setItemsCollapsedState] = useState([true, true]);
  const { menuItems, menuId } = props;
  const { content, innerMenu } = menuItems;

  const onItemClick = (menuIndex) => {
    setInnerMenuIndex(menuIndex);
    // Temp. preventing collapsing until clear test results
    // setItemsCollapsedState(itemsCollapsedState.map((isCollapsed, index) => index === menuIndex ? !isCollapsed : false ))
  };

  const hasSubItems = (itemIndex) => {
    return innerMenu.items[itemIndex] && innerMenu.items[itemIndex].subItems;
  };

  const getContent = () => {
    if (!innerMenu) {
      return content;
    }

    const items = innerMenu.items;
    // inner content will be displayed if exists
    if (items) {
      const currentItem = items[innerMenuIndex];
      if (hasSubItems(innerMenuIndex)) {
        const currentSubItem = currentItem.subItems[innerMenuSubIndex];
        return currentSubItem.content;
      }
      return currentItem.content;
    }
  };

  return (
    <div className={classnames("structured-header-menu-component", menuId, { "with-left-panel": innerMenu })}>
      <div className="structured-header-menu">
        <div className="structured-header-menu-top-part">
          {innerMenu && (
            <StructuredHeaderMenuLeftPanelComponent
              menu={innerMenu}
              hasSubItems={hasSubItems}
              innerMenuIndex={innerMenuIndex}
              innerMenuSubIndex={innerMenuSubIndex}
              itemsCollapsedState={itemsCollapsedState}
              onItemClick={onItemClick}
              setInnerMenuIndex={setInnerMenuIndex}
              setInnerMenuSubIndex={setInnerMenuSubIndex}
              menuId={menuId}
            />
          )}
          <StructuredHeaderMenuContentComponent menuId={menuId} content={getContent()} />
        </div>
        <div className="structured-header-menu-bottom-part">
          {menuItems.bottomPanel && (
            <StructuredHeaderMenuBottomPartComponent menuId={menuId} panel={menuItems.bottomPanel} />
          )}
        </div>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default StructuredHeaderMenuComponent;
