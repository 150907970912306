import css from "styled-jsx/css";
import { ALWAYS_ON_TOP } from "/constants/z-index";
import BasicHeaderConstants from "segments/desktop/header/layouts/components/basic-header/constants";
import {
  MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH,
  SMALL_MOBILE_QUERY_INCLUDED_MAX_WIDTH,
  MOBILE_SMALL_PHONE_MAX_WIDTH
} from "constants/vp-sizes";
import Constants from "../constants";

const BASIC_HEADER_HEIGHT = `${BasicHeaderConstants.BASIC_HEADER_HEIGHT}px`;
const HEADER_MENU_HEIGHT = `calc(100% - ${BasicHeaderConstants.BASIC_HEADER_HEIGHT - 1}px)`;
const HEADER_MENU_TRANSITION = `opacity ${Constants.HEADER_MENU_TRANSITION_DURATION}ms ease, visibility ${Constants.HEADER_MENU_TRANSITION_DURATION}ms ease;`;
const BASE_HEADER_HEIGHT_WITH_SECONDARY = `${
  BasicHeaderConstants.MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT +
  BasicHeaderConstants.MOBILE_MINI_SITE_SECONDARY_HEADER_HEIGHT
}px`;
const MOBILE_MINISITE_HEADER_MENU_HEIGHT = `calc(100% - ${
  BasicHeaderConstants.MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT - 1
}px)`;
const MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT = `${BasicHeaderConstants.MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT}px`;

export const componentStyles = css.global`
  .base-header-mobile-component {
    width: 100%;
    height: ${BASIC_HEADER_HEIGHT};

    &.secondary-header {
      height: ${BASE_HEADER_HEIGHT_WITH_SECONDARY};
    }
    &.with-cluster-logo:not(.secondary-header) {
      height: ${MOBILE_MINISITE_HEADER_MENU_HEIGHT};
    }

    &.with-cluster-logo {
      .header-menu-component-wrapper {
        top: ${BasicHeaderConstants.MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT - 1 + "px"};
        height: ${MOBILE_MINISITE_HEADER_MENU_HEIGHT};
      }

      .mobile-basic-header {
        height: ${MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT};

        .basic-header-content-wrapper {
          height: ${MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT};

          img {
            height: ${MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT};
          }

          .basic-header-content {
            height: ${MOBILE_MINI_SITE_BASIC_HEADER_HEIGHT};
            justify-content: space-between;
            padding-left: 24px;
            padding-right: 24px;

            .main-logo-wrapper {
              height: 100%;
              a {
                height: 100%;
                display: flex;
                .picture-component {
                  display: flex;
                  align-items: center;
                  .base-header-monday-logo-image {
                    max-width: calc(100% - 32px);
                    height: unset;
                    max-height: 60px;
                    margin-right: 0;
                  }
                }
              }
            }
          }
        }
      }

      .mobile-menu-right-section {
        flex-grow: 0;

        .mobile-header-content {
          .hamburger-menu {
            margin: 0;
          }
        }
      }
    }

    &.sticky-signup {
      .basic-header-component {
        .basic-header-content-wrapper {
          .basic-header-content {
            padding: 0 16px;
            @media (max-width: ${MOBILE_SMALL_PHONE_MAX_WIDTH}) {
              padding: 0 12px;
              .mobile-header-content {
                .hamburger-menu {
                  width: 22px;
                }
              }
            }

            .mobile-menu-right-section {
              .sticky-signup-wrapper {
                @media (max-width: ${SMALL_MOBILE_QUERY_INCLUDED_MAX_WIDTH}) {
                  display: none;
                }
                .regular-button {
                  .button-component {
                    padding: 8px 12px 9.5px 12px;
                    font-size: 0.8125rem;
                    font-weight: 300;
                  }
                }
              }

              .mobile-header-content {
                .hamburger-menu {
                  margin-left: 16px;
                  margin-right: 0;
                }
              }
            }
          }
        }
      }
    }

    .mobile-menu-right-section {
      flex-grow: 1;
      display: flex;
      justify-content: flex-end;

      .mobile-header-content {
        .hamburger-menu {
          span {
            height: 2px !important;
          }
        }
      }
    }

    .header-menu-component-wrapper {
      position: fixed;
      z-index: ${ALWAYS_ON_TOP};
      top: ${BasicHeaderConstants.BASIC_HEADER_HEIGHT - 1 + "px"};
      opacity: 0;
      height: ${HEADER_MENU_HEIGHT};
      width: 100%;
      visibility: hidden;
      transition: ${HEADER_MENU_TRANSITION};

      &.is-open {
        opacity: 1;
        visibility: visible;
      }

      @media (max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_MAX_WIDTH}) {
        max-width: 100vw;
      }
    }
  }
`;
