import { useCallback } from "react";
import classnames from "classnames";
import colors from "styles/colors";
import HeaderMenuSectionComponent from "./header-menu-section/header-menu-section-component";
import { componentStyles } from "./sections-header-menu-component.scss";

const SectionsHeaderMenuComponent = (props) => {
  const { menuItems, menuId } = props;

  const renderHeaderMenuSections = useCallback(() => {
    return menuItems.map((menuSection, index) => {
      const { backgroundColor, classname, noSeparator, title, hasFirstSeparator } = menuSection;
      const isLastItem = menuItems.length - 1 === index;
      const hasBackgroundColor = !!backgroundColor;

      return (
        <>
          {hasFirstSeparator && <div className={classnames("sections-separator", classname)} />}
          <div
            key={title}
            className={classnames("header-menu-section-component-wrapper", classname, {
              "has-background-color": hasBackgroundColor
            })}
            style={{ "--section-background-color": colors[backgroundColor] || "transparent" }}
          >
            <HeaderMenuSectionComponent {...menuSection} menuId={menuId} />
          </div>
          {!isLastItem && !noSeparator && <div className={classnames("sections-separator", classname)} />}
        </>
      );
    });
  }, [menuItems]);

  return (
    <div className="sections-header-menu-component">
      <div className="header-menu-sections-wrapper">{renderHeaderMenuSections()}</div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default SectionsHeaderMenuComponent;
