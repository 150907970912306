export default {
  HEADER_MENU_TRANSITION_DURATION: 300,
  SCROLL_PIXEL_THRESHOLD: 100,
  SCROLL_BACK_UP_THRESHOLD: 40,
  SECONDARY_HEADER_SCROLL_FIRST_TRANSITION_DURATION: 50,
  SECONDARY_HEADER_SCROLL_DISSAPEARING_LINKS_DURATION: 250,
  SECONDARY_HEADER_SCROLL_FULL_TRANSITION_DURATION: 500,
  HEADER_MENU_COMPONENT_WRAPPER_CLASS_NAME: "header-menu-component-wrapper",
  HEADER_DESKTOP_SEGMENT_NAME: "BaseHeaderDesktopComponent"
};
