import { PureComponent } from "react";
import { componentStyles } from "./long-footer-component.scss";
import LongFooter from "segments/desktop/core-components/long-footer/long-footer";
import { FOOTER_ID } from "constants/element-ids";

export default class LongFooterComponent extends PureComponent {
  render() {
    return (
      <div className="long-footer-component" id={FOOTER_ID}>
        <LongFooter {...this.props} />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

LongFooterComponent.defaultProps = {};
