import { PureComponent } from "react";
import { componentStyles } from "./download-mobile-app-component.scss";
import PictureComponent from "components/core/picture/picture-component";
import { isAndroid } from "/utils/mobile";
import { IOS_APP_DOWNLOAD_LINK, ANDROID_APP_DOWNLOAD_LINK } from "/constants/links";

const ANDROID_SRC = "https://dapulse-res.cloudinary.com/image/upload/mobile_menu/google-play-badge.png";
const IPHONE_SRC =
  "https://dapulse-res.cloudinary.com/image/upload/mobile_menu/Download_on_the_App_Store_Badge_US-UK_RGB_blk_092917_1.png";

export default class DownloadMobileAppComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isAndroidPhone: false
    };
  }

  componentDidMount() {
    this.setState({ isAndroidPhone: isAndroid() });
  }

  downloadMobileApp = () => {
    const { isAndroidPhone } = this.state;
    BigBrain("track", "hp_store_clicked", { kind: isAndroidPhone ? "android" : "ios" });
    window.location.href = isAndroidPhone ? ANDROID_APP_DOWNLOAD_LINK : IOS_APP_DOWNLOAD_LINK;
  };

  render() {
    const { isAndroidPhone } = this.state;
    const imgSrc = isAndroidPhone ? ANDROID_SRC : IPHONE_SRC;

    return (
      <div className="download-mobile-app-component" onClick={this.downloadMobileApp}>
        <PictureComponent src={imgSrc} className={"download-mobile-app-image"} />
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
