import { withGenericConfig } from "contexts/generic-config-context";
import { componentStyles } from "./structured-header-menu-bottom-part.scss";
import SocialProofComponent from "./structured-header-menu-bottom-part-components/social-proof-component";
import BottomItemsComponent from "./structured-header-menu-bottom-part-components/bottom-items-component";

const StructuredHeaderMenuBottomPartComponent = (props) => {
  const { menuId, panel } = props;
  const { bottomItems: items, isSocialProofDisplayed } = panel;

  return (
    <div className="structured-header-menu-bottom-part-component">
      {isSocialProofDisplayed && <SocialProofComponent />}
      <div className="spacer" />
      {items && <BottomItemsComponent menuId={menuId} items={items} />}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(StructuredHeaderMenuBottomPartComponent);
