import { PureComponent } from "react";
import classnames from "classnames";
import BasicHeaderComponent from "segments/desktop/header/layouts/components/basic-header/basic-header-component";
import HeaderMenuComponent from "segments/desktop/header/layouts/base-header/base-header-mobile/header-menu/header-menu-component";
import HamburgerMenuComponent from "components/core/top-bar-new/hamburger-menu/hamburger-menu-component";
import { withGenericConfig } from "/contexts/generic-config-context";
import { getClusterDesktopLogo, getMiniSiteLogo } from "client/services/cluster-config-service/cluster-config-service";
import Banner from "segments/desktop/core-components/banner/banner";
import { NEW_SOFT_SIGNUP_LINK } from "constants/links";
import { withAbTests } from "/contexts/ab-tests-context";
import { HEADER_POPUP_DATA } from "constants/header-popup";
import { shouldGetMobileStickySignupAbTest } from "services/ab-tests/acquisition-ab-test-service";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { componentStyles } from "./base-header-mobile-component.scss";
import constants from "../constants";
import SecondaryHeaderMobileComponent from "../secondary-header/secondary-header-mobile/secondary-header-mobile-component";

const SCROLL_MODE_THRESHOLD = 2; // The amount of pixels from the top that will trigger the scrolled mode of the navbar
const MONDAY_WHITE_LOGO =
  "https://dapulse-res.cloudinary.com/image/upload/remote_mondaycom_static/img/monday_logo_white.png";

class BaseHeaderMobileComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.state = {
      isMenuOpen: false,
      isScrolled: false
    };
  }

  componentDidMount() {
    window.addEventListener("scroll", this.scrollListener);
  }

  componentWillUnmount() {
    window.removeEventListener("scroll", this.scrollListener);
  }

  isScrolled = () => {
    return (window?.pageYOffset || document?.documentElement?.scrollTop) >= SCROLL_MODE_THRESHOLD;
  };

  scrollListener = (event) => {
    const { isScrolled } = this.state;
    const isInScrollMode = this.isScrolled();

    if (isInScrollMode !== isScrolled) {
      this.setState({ isScrolled: isInScrollMode });
    }
  };

  shouldDisplayClusterizedLogo = () => {
    const { pageClusterConfig, isSecondaryMenuNeeded, pageClusterId, pageSubClusterId, useProductMiniSiteConfig } =
      this.props;
    if (pageClusterConfig && (isSecondaryMenuNeeded || pageClusterId || pageSubClusterId)) {
      return !!getMiniSiteLogo(pageClusterConfig, { useProductMiniSiteConfig });
    }
    return false;
  };

  getMondayLogo = () => {
    const { pageClusterConfig, useProductMiniSiteConfig } = this.props;
    const { logoHref, logoSrc } = this.props;

    if (this.shouldDisplayClusterizedLogo()) {
      const useDesktopLogoInMobile = pageClusterConfig?.mini_site?.logo?.useDesktopLogoInMobile === "true";
      return useDesktopLogoInMobile
        ? getClusterDesktopLogo(pageClusterConfig, { useProductMiniSiteConfig })
        : getMiniSiteLogo(pageClusterConfig, { useProductMiniSiteConfig });
    }

    return {
      src: this.isDarkMode() ? MONDAY_WHITE_LOGO : logoSrc,
      url: logoHref
    };
  };

  enterMobileMenuAbTest = () => {
    // leaving for future menu ab tests
    const { abTests } = this.props;
  };

  toogleOpenMenu = (isMenuOpen) => {
    if (isMenuOpen) {
      this._basicHeader.forceShadow();
      this.enterMobileMenuAbTest();

      // Remove focus when opening the menu
      // Should close all native autocompletes
      if (document?.activeElement) {
        document.activeElement.blur();
      }
    } else {
      // Close all tabs in link menus in case the menu will be closed
      setTimeout(() => this._headerMenu?.openLinksMenu(null), constants.HEADER_MENU_TRANSITION_DURATION);

      this._basicHeader.unforceShadow();
    }

    this.setState({ isMenuOpen });
  };

  renderLogoPopupIfNeeded = () => {
    const { translate, logoPopup, localeId } = this.props;
    const popupData = HEADER_POPUP_DATA[logoPopup];

    if (!popupData) return null;
    const key = popupData.mobileTextKey || popupData.textKey;
    const imageSource = popupData.mobileImageSrc || popupData.gifSrc;
    const bannerText = translate(key).replace("<br>", " "); // desktop popup text can have specific breaks

    return (
      <Banner
        withSignupButton={false}
        bannerText={bannerText}
        bannerLinkData={{
          text: translate(popupData.ctaKey),
          src: popupData.getLink(localeId)
        }}
        theme={popupData.bannerTheme}
        bannerleftImage={imageSource}
      />
    );
  };

  renderSignup = () => {
    const { translate } = this.props;
    return (
      <div className="sticky-signup-wrapper">
        <RegularButton
          buttonText={translate("signup.cta")}
          url={NEW_SOFT_SIGNUP_LINK}
          color={WORK_OS_IRIS_COLOR_NAME}
        />
      </div>
    );
  };

  shouldShowStickySignup = () => {
    const { abTests, mobileStickySignup } = this.props;
    if (this.shouldDisplayClusterizedLogo()) return false;
    if (mobileStickySignup) return true;

    return shouldGetMobileStickySignupAbTest(abTests, true);
  };

  isDarkMode = () => {
    const { mobileDarkHeader } = this.props;
    const { isScrolled } = this.state;

    return mobileDarkHeader && !isScrolled;
  };

  render() {
    const { showMenu, isSecondaryMenuNeeded, translate } = this.props;
    const { isMenuOpen } = this.state;
    const logo = this.getMondayLogo();
    const displayClusterLogo = this.shouldDisplayClusterizedLogo();
    const renderStickySignup = this.shouldShowStickySignup();

    return (
      <>
        <header
          className={classnames("base-header-mobile-component", {
            "secondary-header": isSecondaryMenuNeeded,
            "with-cluster-logo": displayClusterLogo,
            "sticky-signup": renderStickySignup
          })}
        >
          <BasicHeaderComponent
            ref={(element) => (this._basicHeader = element)}
            showMenu={showMenu}
            imageHref={logo.url}
            imageSrc={logo.src}
            isMobile={true}
            isSecondaryMenuNeeded={isSecondaryMenuNeeded}
            className="mobile-basic-header"
            isMenuOpen={isMenuOpen}
            darkMode={this.isDarkMode()}
          >
            <div className="mobile-menu-right-section">
              {renderStickySignup && this.renderSignup()}
              <div className="mobile-header-content" aria-label={translate("header.mainMenu")} role="navigation">
                <HamburgerMenuComponent changeStateCallback={this.toogleOpenMenu} isOpen={isMenuOpen} />
              </div>
            </div>
          </BasicHeaderComponent>

          {isSecondaryMenuNeeded && <SecondaryHeaderMobileComponent />}

          <div
            className={classnames("header-menu-component-wrapper", {
              "is-open": isMenuOpen
            })}
          >
            <HeaderMenuComponent
              ref={(element) => (this._headerMenu = element)}
              isMinisitePage={isSecondaryMenuNeeded}
            />
          </div>

          <style jsx>{componentStyles}</style>
        </header>
        {this.renderLogoPopupIfNeeded()}
      </>
    );
  }
}

export default withGenericConfig(withAbTests(BaseHeaderMobileComponent));
