import css from "styled-jsx/css";
import {
  BACKGROUND_GRAY_COLOR_NAME,
  BRAND_DARK_COLOR,
  BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01,
  DEV_DARKER_TINT_02,
  DEV_LIGHT_TINT_02
} from "styles/color-consts";
import Colors from "styles/colors";

export const COLLAPSE_TRANSITION_DURATION_IN_MIL_SEC = 150;

const ARROW_TRANSITION = `${COLLAPSE_TRANSITION_DURATION_IN_MIL_SEC}ms transform ease-in-out`;
const PANEL_TRANSITION = `${COLLAPSE_TRANSITION_DURATION_IN_MIL_SEC}ms max-height ease-in-out,${COLLAPSE_TRANSITION_DURATION_IN_MIL_SEC}ms margin ease-in-out`;

export const componentStyles = css.global`
  .structured-header-menu-left-panel {
    min-width: 320px;
    padding: 24px 24px 24px 0;
    box-shadow: 32px 0 40px -32px rgba(29, 140, 242, 0.16);
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    .structured-header-menu-left-panel-items {
      display: flex;
      flex-direction: column;
      .inner-menu-item {
        display: flex;
        flex-direction: column;
        border-top: ${`1px solid ${Colors[BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01]}`};
        // for keyboard focus outline visibility
        margin: 1px;
        &:last-child {
          border-bottom: ${`1px solid ${Colors[BRAND_WORK_MANAGEMENT_LIGHTER_TINT_01]}`};
        }
        &.collapsed {
          .inner-menu-item-subitems {
            max-height: 160px;
            margin: 0 0 16px;
          }
        }
        .inner-menu-item-title {
          padding: 16px 0;
          font-size: 1rem;
          font-weight: 400;
          display: flex;
          gap: 8px;
          align-items: center;
          justify-content: space-between;
          .expand-arrow {
            transform: rotate(-90deg);
            transition: ${ARROW_TRANSITION};
            display: flex;
            margin-right: 14px;
            &.down-direction {
              transform: rotate(0deg);
            }
          }
        }
        .inner-menu-item-subitems {
          display: flex;
          flex-direction: column;
          margin: 0px;
          max-height: 0px;
          overflow: hidden;
          transition: ${PANEL_TRANSITION};
          .inner-menu-item-subitem {
            display: flex;
            justify-content: space-between;
            font-size: 1rem;
            line-height: 40px;
            padding-left: 8px;
            border-top-right-radius: 8px;
            border-bottom-right-radius: 8px;
            // for keyboard focus outline visibility
            margin: 1px;
            &.selected {
              background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
            }
            .inner-menu-item-subitem-title-and-icon {
              align-items: center;
              display: flex;
              gap: 16px;
              .inner-menu-item-subitem-title-icon {
                height: 22px;
                width: 22px;
              }
              .inner-menu-item-subitem-tag {
                font-size: 0.6875rem;
                font-weight: 400;
                color: ${Colors[DEV_DARKER_TINT_02]};
                background-color: ${Colors[DEV_LIGHT_TINT_02]};
                border-radius: 2px;
                padding: 1px 5px;
                line-height: normal;
              }
            }
            .inner-menu-item-subitem-title-arrow {
              margin-right: 16px;
              .arrow-icon {
                path {
                  fill: ${Colors.asphalt};
                }
                height: 8px;
              }
            }
          }
        }
      }
    }
  }
`;
