import { PureComponent } from "react";
import { componentStyles } from "./all-rights-reserved-component.scss.js";
import { withGenericConfig } from "/contexts/generic-config-context";
import { withRouter } from "next/router";

class AllRightsReservedComponent extends PureComponent {
  getText() {
    const { translate } = this.props;
    return translate("allRightsReserved");
  }

  render() {
    return (
      <div className="all-rights-reserved-component">
        <div className="details">{this.getText()} © monday.com</div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withGenericConfig(withRouter(AllRightsReservedComponent));
