import css from "styled-jsx/css";
import Colors from "/styles/colors";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";
import HeaderConstants from "../../constants";

const transparentBorder = `1px solid transparent`;
const border = `1px solid ${Colors["riverstone"]}`;
const HEADER_CONTENT_HORIZONTAL_PADDING = `${Constants.HEADER_CONTENT_HORIZONTAL_PADDING}px`;

const UNIFIED_HEADER_LOGO_TRANSITION = `opacity ${HeaderConstants.SECONDARY_HEADER_SCROLL_FIRST_TRANSITION_DURATION}ms ease, transform ${HeaderConstants.SECONDARY_HEADER_SCROLL_FIRST_TRANSITION_DURATION}ms ease`;
const UNIFIED_HEADER_LINKS_TRANSITION = `opacity ${HeaderConstants.SECONDARY_HEADER_SCROLL_FULL_TRANSITION_DURATION}ms ease, transform ${HeaderConstants.SECONDARY_HEADER_SCROLL_FULL_TRANSITION_DURATION}ms ease`;
const UNIFIED_HEADER_TRANSITION = `opacity ${HeaderConstants.SECONDARY_HEADER_SCROLL_FULL_TRANSITION_DURATION}ms ease`;
const SECONDARY_HEADER_HEIGHT_WITH_BORDER = `${Constants.SECONDARY_HEADER_HEIGHT + 1}px`;
const SECONDARY_HEADER_HEIGHT = `${Constants.SECONDARY_HEADER_HEIGHT}px`;

export const componentStyles = css.global`
  .secondary-header-desktop-component {
    display: flex;
    justify-content: center;
    width: 100%;
    border-top: ${border};
    border-bottom: ${transparentBorder};
    transition: ${UNIFIED_HEADER_TRANSITION};
    height: ${SECONDARY_HEADER_HEIGHT_WITH_BORDER};
    background-color: ${Colors["white"]};

    .secondary-header-desktop-content {
      height: 100%;
      max-width: ${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH + "px"};
      width: 100%;

      padding-left: ${HEADER_CONTENT_HORIZONTAL_PADDING};
      padding-right: ${HEADER_CONTENT_HORIZONTAL_PADDING};
      display: flex;

      .logo-wrapper {
        padding-right: 16px;
        transition: ${UNIFIED_HEADER_LOGO_TRANSITION};

        .secondary-header-logo-image {
          height: ${SECONDARY_HEADER_HEIGHT};
        }
      }

      .links-wrapper {
        height: 100%;
        display: flex;
        align-items: center;
        transition: ${UNIFIED_HEADER_LINKS_TRANSITION};

        .secondary-link-item-wrapper {
          height: 100%;
          display: flex;
        }
      }
    }
  }

  .scroll-started {
    .secondary-header-wrapper {
      .secondary-header-desktop-component {
        border-bottom: ${border};
      }
    }
  }

  .scrolled-threshold {
    .secondary-header-wrapper {
      .secondary-header-desktop-component {
        opacity: 0;
        visibility: hidden;

        .logo-wrapper {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-5px);
        }
        .links-wrapper {
          opacity: 0;
          visibility: hidden;
          transform: translateY(-5px);
        }
      }
    }
  }
`;
