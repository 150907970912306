import { PureComponent } from "react";
import { componentStyles } from "./link-item-component.scss";
import { withGenericConfig } from "contexts/generic-config-context";
import { HEADER_LINK_CLICKED } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";

class LinkItemComponent extends PureComponent {
  onClick = (e) => {
    const { url, title, clickEvent } = this.props;
    const isKeyboardEvent = e?.screenX === 0 && e?.screenY === 0;
    trackEvent(HEADER_LINK_CLICKED, { kind: url, info1: isKeyboardEvent ? "keyboard" : "mouse", info2: title });
    if (clickEvent) clickEvent();
  };

  render() {
    const { title, url, translate } = this.props;

    return (
      <a href={url} className="link-item-component" onClick={this.onClick} tabIndex="0" onKeyPress={this.onClick}>
        {translate(title)}

        <style jsx>{componentStyles}</style>
      </a>
    );
  }
}

export default withGenericConfig(LinkItemComponent);
