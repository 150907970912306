import css from "styled-jsx/css";
import { MOBILE_QUERY_MAX_WIDTH } from "constants/vp-sizes";
import colors from "styles/colors";

export const componentStyles = css.global`
  .all-rights-reserved-component {
    .details {
      font-size: 0.8125rem;
      line-height: 2;
      height: 24px;
      color: ${colors["text-gray"]};
    }
  }

  @media (max-width: ${MOBILE_QUERY_MAX_WIDTH}) {
    .all-rights-reserved-component {
      display: flex;
      margin-left: 24px;

      .icons-wrapper {
        margin-left: 48px;
      }
    }
  }
`;
