import css from "styled-jsx/css";
import Colors from "/styles/colors";
import Constants from "segments/desktop/header/layouts/components/basic-header/constants";

const OPACITY_WIDTH = "48px";
const SECONDARY_HEIGHT = `${Constants.MOBILE_MINI_SITE_SECONDARY_HEADER_HEIGHT}px`;

export const componentStyles = css.global`
  .secondary-header-mobile-component {
    height: ${SECONDARY_HEIGHT};
    width: 100%;
    outline: solid 1px;
    outline-color: ${Colors["border-gray"]};
    position: fixed;
    background-color: #fff;
    z-index: 555;
    box-shadow: 0px 4px 40px rgba(0, 0, 0, 0.08);

    &.scroll-end {
      &::after {
        opacity: 0;
      }
    }

    &::after {
      content: "";
      position: absolute;
      right: 0;
      top: 0;
      height: 100%;
      width: ${OPACITY_WIDTH};
      background: linear-gradient(90deg, rgba(255, 255, 255, 0) -140%, #ffffff 180%);
      opacity: 1;
      pointer-events: none;
    }

    .links-wrapper {
      display: flex;
      overflow: scroll;
      height: 100%;

      &::-webkit-scrollbar {
        display: none;
      }

      .secondary-link-item-wrapper {
        white-space: nowrap;
        display: flex;

        &:first-child {
          padding-left: 8px;
        }

        &:last-child {
          .secondary-link-item-component {
            margin-right: 24px;
          }
        }

        .secondary-link-item-component {
          padding-left: 8px;
          padding-right: 8px;
          margin: 0 8px;
          font-size: 0.8125rem;

          &:hover {
            color: ${Colors["asphalt"]};
          }
        }
      }
    }
  }
`;
