import { PureComponent } from "react";
import classnames from "classnames";
import { componentStyles } from "./link-item-with-menu-component.scss";
import ArrowDown from "static/svg/arrow-down.svg";
import { withGenericConfig } from "contexts/generic-config-context";
import { generateOnKeyDownHandler } from "/client/services/accessibility/accessibility-service";

const LIST_ITEM_WITH_MENU_CLASS_NAME = "link-item-with-menu-component";
const HOVER_OPEN_MENU_WAIT_MILISECONDS = 500;

class LinkItemWithMenuComponent extends PureComponent {
  constructor(props) {
    super(props);

    this.onKeyDown = generateOnKeyDownHandler(this.onClickAction);
    this._hoverTimeout = null;
  }

  onMouseLeave = (event) => {
    const { isOpen, closeMenu } = this.props;

    this.clearHoverOpenMenuTimeoutIfNeeded();
    if (isOpen) {
      closeMenu(event);
      event?.target?.blur();
    }
  };

  onMouseEnter = (event) => {
    const { openMenu } = this.props;
    this._hoverTimeout = setTimeout(openMenu, this.getHoverWaitTime());
  };

  getHoverWaitTime = () => {
    const { shouldDelayMenuOpenOnHover } = this.props;
    return shouldDelayMenuOpenOnHover ? HOVER_OPEN_MENU_WAIT_MILISECONDS : 0;
  };

  clearHoverOpenMenuTimeoutIfNeeded = () => {
    if (this._hoverTimeout) {
      clearTimeout(this._hoverTimeout);
      this._hoverTimeout = null;
    }
  };

  onClickAction = (event) => {
    const { openMenu, isOpen, closeMenu } = this.props;
    if (isOpen) {
      closeMenu();
    } else {
      openMenu();
    }
  };

  blurItem = (e) => {
    e?.target?.closest(`.${LIST_ITEM_WITH_MENU_CLASS_NAME}`)?.blur();
  };

  onClick = (e) => {
    this.blurItem(e);
    this.onClickAction(e);
  };

  render() {
    const { title, isOpen, translate, className } = this.props;

    return (
      <div
        className={classnames(LIST_ITEM_WITH_MENU_CLASS_NAME, className, { "is-open": isOpen })}
        onKeyDown={this.onKeyDown}
        onClick={this.onClick}
        role="link"
        tabIndex={0} //make it focusable
        aria-haspopup="true"
        aria-expanded={isOpen}
      >
        <span className="link-item-with-menu-title">{translate(title)}</span>
        <ArrowDown className="link-item-with-menu-icon" />

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

LinkItemWithMenuComponent.defaultProps = {
  shouldDelayMenuOpenOnHover: false
};

export default withGenericConfig(LinkItemWithMenuComponent);
