import { useCallback } from "react";
import { withGenericConfig } from "contexts/generic-config-context";
import BadgeComponent from "components/core/badge/badge-component";
import { HEADER_PRODUCT_TAG_HOVERED, HEADER_SUBMENU_LINK_CLICKED } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";
import PictureComponent from "components/core/picture/picture-component";
import classnames from "classnames";
import { ICON_TYPES, TAG_TYPES } from "segments/desktop/header/layouts/base-header/base-header-service";
import { BACKGROUND_GRAY_COLOR_NAME, WHITE_COLOR_NAME, WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import colors from "styles/colors";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import BulletCheck from "static/svg/bullet_checked_green.svg";
import BulletPlus from "static/svg/bullet_plus_green_2.svg";
import { componentStyles } from "./header-menu-section-item-component.scss";

const HeaderMenuSectionItemComponent = (props) => {
  const {
    icon: Icon,
    iconType,
    iconColor,
    imageSrc,
    topic,
    title,
    subtitle,
    subtitleIcon: SubtitleIcon,
    description,
    url,
    isPopular,
    translate,
    badgeText,
    badgeColorName,
    badgeTextColorName,
    badgeStrokeColorName,
    openInNewTab,
    button,
    menuId,
    sectionId,
    iconStrokeColor,
    tags,
    buttonText,
    outline,
    itemType,
    backgroundColor,
    hoverBackgroundColor,
    subtitleAboveItem
  } = props;

  const onClick = useCallback(
    (e) => {
      const isKeyboardEvent = e?.screenX === 0 && e?.screenY === 0;
      trackEvent(HEADER_SUBMENU_LINK_CLICKED, {
        kind: url,
        info1: isKeyboardEvent ? "keyboard" : "mouse",
        info2: menuId,
        info3: sectionId
      });
    },
    [url]
  );

  const onTagMouseEnter = (tag, index) => {
    trackEvent(HEADER_PRODUCT_TAG_HOVERED, {
      kind: translate(title),
      info1: translate(tag),
      info2: index
    });
  };

  const renderMenuItemText = () => (
    <div className="menu-item-text-wrapper">
      {topic && <div className="menu-item-topic-wrapper">{translate(topic)}</div>}
      <div className="menu-item-title-wrapper">
        <span className="menu-item-title">{translate(title)}</span>
        {isPopular && (
          <div className="new-badge-component-wrapper">
            <BadgeComponent
              badgeText={translate(badgeText)}
              badgeColorName={badgeColorName}
              badgeTextColorName={badgeTextColorName}
              badgeStrokeColorName={badgeStrokeColorName}
            />
          </div>
        )}
      </div>
      {subtitle && (
        <div className="menu-item-subtitle-wrapper">
          {SubtitleIcon && <SubtitleIcon />}
          <span className="menu-item-title">{translate(subtitle)}</span>
        </div>
      )}
      {description && (
        <span className="menu-item-description">
          <MondayMarkupLanguageComponent text={translate(description)} />
        </span>
      )}
      {button && (
        <div className="menu-item-description-button">
          <SecondaryButton buttonText={translate(buttonText || "header.menuItem.button.readMore")} url={url} isOpenLinkInNewTab={openInNewTab} />
        </div>
      )}
    </div>
  );

  const renderTag = ({ tag, index }) => {
    const tagsType = tags?.tagsType || TAG_TYPES.DEFAULT;

    const renderBulletTag = () => {
      const BulletIcon = index === tags?.list?.length - 1 ? BulletPlus : BulletCheck;

      return (
        <>
          <BulletIcon className="tag-bullet" />
          {translate(tag)}
        </>
      );
    };

    return (
      <div key={index} className="tag" onMouseEnter={() => onTagMouseEnter(tag, index)}>
        {tagsType === TAG_TYPES.BULLET ? renderBulletTag() : translate(tag)}
      </div>
    );
  };

  const renderTags = ({ className }) => {
    return (
      tags && (
        <div
          className={classnames("tags-wrapper", className)}
          style={{
            "--header-product-tag-background-color": tags.backgroundColor,
            "--header-product-tag-color": tags.color
          }}
        >
          {tags.title && <div className="tags-title">{`${translate(tags.title)}:`}</div>}
          {tags.list.map((tag, index) => renderTag({ tag, index }))}
        </div>
      )
    );
  };

  const getStyle = () => {
    const backgroundColorValue = backgroundColor || colors[WHITE_COLOR_NAME];
    const hoverBackgroundColorValue = hoverBackgroundColor || colors[BACKGROUND_GRAY_COLOR_NAME];

    return {
      "--header-menu-section-item-background-color": backgroundColorValue,
      "--header-menu-section-item-hover-background-color": hoverBackgroundColorValue
    };
  };

  return (
    <>
      {subtitleAboveItem && <div>{translate(subtitleAboveItem)}</div>}
      <div
        key={title}
        className={classnames("header-menu-section-item-component-wrapper", itemType, {
          outline
        })}
        style={getStyle()}
      >
        <a
          className="header-menu-section-item-component"
          href={url}
          onClick={onClick}
          target={openInNewTab ? "_blank" : "_self"}
          rel="noreferrer"
        >
          <div
            className="header-menu-item-content-and-tags-wrapper"
            style={{ display: "flex", flexDirection: "column" }}
          >
            <div
              className={classnames("header-menu-item-content", {
                "has-image-instead-title": !!imageSrc,
                "no-description": !description
              })}
            >
              {Icon && (
                <Icon
                  className={classnames("menu-item-title-icon", iconType)}
                  style={{
                    "--icon-color": colors[iconColor || WORK_OS_IRIS_COLOR_NAME],
                    "--icon-stroke-color": iconStrokeColor
                  }}
                />
              )}
              {imageSrc && <PictureComponent src={imageSrc} />}
              <div className="menu-item-text-and-tags-wrapper">
                {renderMenuItemText()}
                {renderTags({ className: "tags-next-to-logo" })}
              </div>
            </div>
            {renderTags({ className: "tags-at-the-bottom" })}
          </div>

          <style jsx>{componentStyles}</style>
        </a>
      </div>
    </>
  );
};

export default withGenericConfig(HeaderMenuSectionItemComponent);

HeaderMenuSectionItemComponent.defaultProps = {
  iconType: ICON_TYPES.outline
};
