import { PureComponent } from "react";
import { componentStyles } from "./secondary-link-item-component.scss";
import classNames from "classnames";
import { SECONDARY_HEADER_LINK_CLICKED } from "/constants/bigbrain-event-types";
import { withGenericConfig } from "contexts/generic-config-context";

class SecondaryLinkItemComponent extends PureComponent {
  onClick = (e) => {
    const { url } = this.props;
    const isKeyboardEvent = e?.screenX === 0 && e?.screenY === 0;
    BigBrain("track", SECONDARY_HEADER_LINK_CLICKED, { kind: url, info1: isKeyboardEvent ? "keyboard" : "mouse" });
  };

  render() {
    const { title, url, isSelected, translate } = this.props;

    return (
      <a
        href={url}
        className={classNames("secondary-link-item-component", { selected: isSelected })}
        onClick={this.onClick}
      >
        {translate(title)}
        <style jsx>{componentStyles}</style>
      </a>
    );
  }
}

export default withGenericConfig(SecondaryLinkItemComponent);
