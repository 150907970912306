import classnames from "classnames";
import {
  shouldHaveLanguagePickerInHeaderTest,
  shouldHaveProductLearnMoreHeaderTest,
  shouldShowSuccessStoriesInHeaderTest
} from "services/ab-tests/acquisition-ab-test-service";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { isExistingUserInHeaderTest, getSignupLink } from "services/monday-platform/existing-accounts-service";
import { withAbTests } from "contexts/ab-tests-context";
import {
  CUSTOMERS_MENU_ID,
  PLATFORM_MENU_ID,
  RESOURCES_MENU_ID,
  SOLUTIONS_MENU_ID,
  solutionsMenuWithHeaderLearnMoreButton,
  StructuredHeaderMenuComponentMapping,
  StructuredHeaderMenuMapping,
  TEAMS_MENU_ID
} from "segments/desktop/header/layouts/base-header/base-header-service";
import HeaderConstants from "segments/desktop/header/layouts/base-header/constants.js";
import LinkItemWithMenuComponent from "segments/desktop/header/layouts/base-header/base-header-desktop/link-item-with-menu/link-item-with-menu-component.js";
import LanguagePickerComponent from "components/core/language-picker/language-picker-component";
import { DIALOG_DIRECTIONS } from "components/core/language-picker/language-picker-service";
import LinkItemComponent from "segments/desktop/header/layouts/base-header/base-header-desktop/link-item/link-item-component";
import { CONTACT_US_FROM_HEADER_PAGE_LINK, LOGIN_PAGE_LINK, PRICING_PAGE_LINK } from "constants/links";
import RegularButton from "segments/desktop/core-components/button/regular-button/regular-button";
import { WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";
import { SM } from "constants/sizes";
import Button from "segments/desktop/core-components/button/button";
import { openContactSalesTypeForm, TYPEFORM_DESKTOP_DEVICE_TYPE } from "constants/typeform-contact-sales";
import { TYPEFORM_UNHANDLED_EXCEPTION } from "constants/bigbrain-event-types";
import { handleClientException } from "client/services/exceptions-handling-service/exceptions-handling-service";
import { withGenericConfig } from "contexts/generic-config-context";
import { shouldGetTypeformExperience } from "services/typeform/typeform-service";
import { translateToEnglish } from "services/localization/localization-service";
import HeaderMenus from "../header-menu";
import { componentStyles } from "./structured-header.scss";

const StructuredHeader = (props) => {
  const {
    showMenu,
    showButton,
    buttonConfig,
    showLanguagePicker,
    shouldShowMainPricingLink,
    isSecondaryMenuNeeded,
    renderUnifiedLinks,
    openSignupPopup,
    hideLogin,
    abTests,
    testTypeformContactSales,
    forceTypeformContactSalesExperience,
    cloudfrontViewerCountry,
    openedMenuId,
    openLinkMenu,
    closeLinkMenu,
    isExistingAccountHeader,
    translate,
    persistantCookies,
    shouldAddProductLearnMoreButtons,
    contactSalesTestMode,
    pageProductId,
    showSuccessStoriesInHeader
  } = props;

  const openSolutionsMenu = () => openLinkMenu(SOLUTIONS_MENU_ID);
  const openTeamsMenu = () => openLinkMenu(TEAMS_MENU_ID);
  const openPlatformMenu = () => openLinkMenu(PLATFORM_MENU_ID);
  const openCustomersMenu = () => openLinkMenu(CUSTOMERS_MENU_ID);
  const openResourcesMenu = () => openLinkMenu(RESOURCES_MENU_ID);

  const isMenuOpen = (menuId) => {
    return openedMenuId === menuId;
  };

  const renderContactSalesButton = () => {
    let buttonURLProp = { url: CONTACT_US_FROM_HEADER_PAGE_LINK };
    if (
      shouldGetTypeformExperience({
        abTests,
        cloudfrontViewerCountry,
        testTypeformContactSales,
        forceTypeformContactSalesExperience
      })
    ) {
      buttonURLProp = {};
    }

    return (
      <div className="link-item-component-wrapper" role="listitem">
        <LinkItemComponent title="header.contactSales" clickEvent={onContactSalesClicked} {...buttonURLProp} />
      </div>
    );
  };

  const renderSubmenuWithTransitionIfNeeded = (menuId) => {
    // always render transition - menu rendered only if open
    const isMenuOpen = openedMenuId === menuId;

    return <div>{isMenuOpen && renderMainHeaderPopup(menuId)}</div>;
  };

  const renderMainHeaderPopup = (menuId) => {
    const headerMenuItems =
      menuId === SOLUTIONS_MENU_ID &&
      (shouldAddProductLearnMoreButtons || shouldHaveProductLearnMoreHeaderTest(abTests))
        ? solutionsMenuWithHeaderLearnMoreButton
        : StructuredHeaderMenuMapping[menuId];

    const MenuComponent = HeaderMenus[StructuredHeaderMenuComponentMapping[menuId]];

    return (
      <div className={HeaderConstants.HEADER_MENU_COMPONENT_WRAPPER_CLASS_NAME} key={menuId}>
        <nav aria-label="main submenu" className="menu-component-wrapper">
          {MenuComponent && <MenuComponent menuItems={headerMenuItems} menuId={menuId} />}
        </nav>
      </div>
    );
  };

  const showSuccessStories = showSuccessStoriesInHeader || shouldShowSuccessStoriesInHeaderTest(abTests, true);

  const renderBaseHeaderLeftLinks = () => {
    return (
      <>
        <div
          className={classnames("link-item-with-menu-component-wrapper", { "is-open": isMenuOpen(SOLUTIONS_MENU_ID) })}
          role="listitem"
        >
          <LinkItemWithMenuComponent
            title="header.products"
            openMenu={openSolutionsMenu}
            closeMenu={closeLinkMenu}
            isOpen={isMenuOpen(SOLUTIONS_MENU_ID)}
          />
        </div>
        {renderSubmenuWithTransitionIfNeeded(SOLUTIONS_MENU_ID)}

        <div
          className={classnames("link-item-with-menu-component-wrapper", { "is-open": isMenuOpen(TEAMS_MENU_ID) })}
          role="listitem"
        >
          <LinkItemWithMenuComponent
            title="header.teams"
            openMenu={openTeamsMenu}
            closeMenu={closeLinkMenu}
            isOpen={isMenuOpen(TEAMS_MENU_ID)}
          />
        </div>
        {renderSubmenuWithTransitionIfNeeded(TEAMS_MENU_ID)}

        <div
          className={classnames("link-item-with-menu-component-wrapper", { "is-open": isMenuOpen(PLATFORM_MENU_ID) })}
          role="listitem"
        >
          <LinkItemWithMenuComponent
            title="header.platform"
            openMenu={openPlatformMenu}
            closeMenu={closeLinkMenu}
            isOpen={isMenuOpen(PLATFORM_MENU_ID)}
          />
        </div>
        {renderSubmenuWithTransitionIfNeeded(PLATFORM_MENU_ID)}

        {showSuccessStories && (
          <div
            className={classnames("link-item-with-menu-component-wrapper", {
              "is-open": isMenuOpen(CUSTOMERS_MENU_ID)
            })}
            role="listitem"
          >
            <LinkItemWithMenuComponent
              title="header.successStories"
              openMenu={openCustomersMenu}
              closeMenu={closeLinkMenu}
              isOpen={isMenuOpen(CUSTOMERS_MENU_ID)}
            />
          </div>
        )}
        {showSuccessStories && renderSubmenuWithTransitionIfNeeded(CUSTOMERS_MENU_ID)}

        <div
          className={classnames("link-item-with-menu-component-wrapper", { "is-open": isMenuOpen(RESOURCES_MENU_ID) })}
          role="listitem"
        >
          <LinkItemWithMenuComponent
            title="header.resources"
            openMenu={openResourcesMenu}
            closeMenu={closeLinkMenu}
            isOpen={isMenuOpen(RESOURCES_MENU_ID)}
          />
        </div>
        {renderSubmenuWithTransitionIfNeeded(RESOURCES_MENU_ID)}
      </>
    );
  };
  const onContactSalesClicked = () => {
    if (
      shouldGetTypeformExperience({
        abTests,
        cloudfrontViewerCountry,
        testTypeformContactSales,
        forceTypeformContactSalesExperience,
        sendBigBrainEvent: true
      })
    ) {
      try {
        openContactSalesTypeForm({
          country: cloudfrontViewerCountry,
          deviceType: TYPEFORM_DESKTOP_DEVICE_TYPE,
          isTest: contactSalesTestMode
        });
      } catch (e) {
        const bbEventParams = { name: TYPEFORM_UNHANDLED_EXCEPTION };
        handleClientException({ e, bbEventParams });
        window.location = CONTACT_US_FROM_HEADER_PAGE_LINK;
      }
    }
  };

  const buildHeaderStructure = () => {
    // init header according to its props
    const headerStructure = {
      leftLinks: { isVisible: showMenu },
      languagePicker: {
        isVisible: !showMenu && (showLanguagePicker || shouldHaveLanguagePickerInHeaderTest(abTests, true))
      },
      pricing: { isVisible: showMenu && shouldShowMainPricingLink() },
      contactSales: { isVisible: showButton && buttonConfig?.buttonType === SIGNUP_BUTTON_TYPE },
      login: { isVisible: showMenu && !hideLogin },
      createNewAccountButton: { isVisible: false },
      button: {
        isVisible: showButton
      }
    };

    if (
      buttonConfig?.buttonType === SIGNUP_BUTTON_TYPE &&
      (isExistingAccountHeader || isExistingUserInHeaderTest(persistantCookies, true))
    ) {
      headerStructure.login.isVisible = false;
      headerStructure.createNewAccountButton.isVisible = true;
      headerStructure.button.extraProps = { forceGoToMyAccount: true };
    }

    return headerStructure;
  };

  const { leftLinks, languagePicker, pricing, contactSales, login, button, createNewAccountButton } =
    buildHeaderStructure();

  return (
    <nav aria-label="Main" className={classnames("base-header-desktop-content")}>
      {leftLinks.isVisible && (
        <div className="left-side-header-links">
          <div className="absolute-main-links" role="list">
            {renderBaseHeaderLeftLinks()}
          </div>
          {isSecondaryMenuNeeded && <div className="absolute-unified-links">{renderUnifiedLinks()}</div>}
        </div>
      )}
      <div className="right-side-header-links">
        {languagePicker.isVisible && (
          <div className={classnames("link-item-component-wrapper", "header-language-picker")}>
            <LanguagePickerComponent
              dialogDirection={DIALOG_DIRECTIONS.ALIGN_BOTTOM}
              showLabel={false}
              showMainLanguagesOnly={true}
            />
          </div>
        )}

        {pricing.isVisible && (
          <div className="link-item-component-wrapper">
            <LinkItemComponent title="header.pricing" url={PRICING_PAGE_LINK} />
          </div>
        )}

        {contactSales.isVisible && renderContactSalesButton()}

        {login.isVisible && (
          <div className="link-item-component-wrapper">
            <LinkItemComponent title="header.logIn" url={LOGIN_PAGE_LINK} />
          </div>
        )}
      </div>

      {createNewAccountButton.isVisible && (
        <div className="header-create-account-button-wrapper">
          <RegularButton
            buttonText={translate("existingAccount.createAccountCta")}
            color={WORK_OS_IRIS_COLOR_NAME}
            outline={true}
            url={getSignupLink(pageProductId)}
            isWithArrow={false}
            buttonSize={SM}
            extraEventData={{ englishText: translateToEnglish("existingAccount.createAccountCta") }}
          />
        </div>
      )}

      {button.isVisible && (
        <div className="header-button-wrapper">
          <Button
            {...buttonConfig}
            parentSegment={HeaderConstants.DESKTOP_HEADER_SEGMENT_NAME}
            onButtonClick={openSignupPopup}
            {...button.extraProps}
          />
        </div>
      )}
      <style jsx>{componentStyles}</style>
    </nav>
  );
};

export default withGenericConfig(withAbTests(StructuredHeader));
