import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .download-mobile-app-component {
    display: flex;
    align-items: center;
    justify-content: center;

    .download-mobile-app-image {
      width: 168px;
    }
  }
`;
