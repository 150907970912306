import { PureComponent } from "react";
import { componentStyles } from "./base-header-component.scss";
import BaseHeaderDesktopComponent from "./base-header-desktop/base-header-desktop-component";
import BaseHeaderMobileComponent from "./base-header-mobile/base-header-mobile-component";
import { MOBILE_OR_TABLE_VIEW } from "/constants/bigbrain-event-types";
import DefaultProps from "./default-props";
import { withGenericConfig } from "/contexts/generic-config-context";
class BaseHeaderComponent extends PureComponent {
  constructor(props) {
    super(props);
    const { isSecondaryMenuNeeded } = props;
    this.isSecondaryMenuNeeded = isSecondaryMenuNeeded;
  }

  componentDidMount() {
    this.trackIsMobileOrTablet();
  }

  trackIsMobileOrTablet() {
    const mobileWrapper = document.querySelector(".mobile-wrapper");

    if (mobileWrapper) {
      const isMobileWrapperRendered = mobileWrapper.offsetParent !== null;
      if (isMobileWrapperRendered) BigBrain("track", MOBILE_OR_TABLE_VIEW, { kind: location.pathname });
    }
  }

  render() {
    return (
      <div className="base-header-component">
        <div className="desktop-wrapper">
          <BaseHeaderDesktopComponent isSecondaryMenuNeeded={this.isSecondaryMenuNeeded} {...this.props} />
        </div>

        <div className="mobile-wrapper">
          <BaseHeaderMobileComponent isSecondaryMenuNeeded={this.isSecondaryMenuNeeded} {...this.props} />
        </div>

        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

BaseHeaderComponent.defaultProps = DefaultProps;

export default withGenericConfig(BaseHeaderComponent);
