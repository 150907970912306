import { PureComponent } from "react";
import { componentStyles } from "./link-component.scss";
import { withGenericConfig } from "contexts/generic-config-context";

class LinkComponent extends PureComponent {
  constructor(props) {
    super(props);
  }

  render() {
    const { title, url, translate, onClickFunc } = this.props;

    return (
      <a className="link-mobile-component" href={url} onClick={onClickFunc}>
        {translate(title)}

        <style jsx>{componentStyles}</style>
      </a>
    );
  }
}

export default withGenericConfig(LinkComponent);
