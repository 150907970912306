import PictureComponent from "components/core/picture/picture-component";
import { FOOTER_LINK_CLICK } from "constants/bigbrain-event-types";
import { ENRICHED_FOOTER_LINKS, MONDAY_LOGO_SRC, ROOT_LINK } from "constants/links";
import PictureWithLinkComponent from "components/core/picture-with-link/picture-with-link-component";
import { componentStyles, linkStyles } from "./footer-category-component.scss";
import { normalizeUrl } from "/utils/url";
import { trackEvent } from "/services/bigbrain-service";
import { withGenericConfig } from "/contexts/generic-config-context";

const FooterCategoryComponent = (props) => {
  const { title, translate, links, type, hasMondayLogoTitle } = props;

  const trackLinkClick = (event) => {
    const { target } = event;
    const href = normalizeUrl(target.href);
    trackEvent(FOOTER_LINK_CLICK, { kind: href });
  };

  const mapLinkByType = ({ linkKey }) => {
    const link = links[linkKey];
    let href, icon, openInNewTab;

    switch (type) {
      case ENRICHED_FOOTER_LINKS:
        icon = link?.icon;
        href = link?.href;
        openInNewTab = link?.openInNewTab;
        break;
      default:
        href = links[linkKey];
    }
    return {
      icon,
      href,
      openInNewTab
    };
  };

  const linkRenderer = (linkKey, index) => {
    const link = mapLinkByType({ linkKey });
    const { href, icon, openInNewTab } = link;

    return (
      <div className="link" key={index}>
        <a onClick={trackLinkClick} href={href} target={openInNewTab ? "_blank" : "_self"} rel="noreferrer">
          {icon && (
            <span>
              <PictureComponent src={icon} />
            </span>
          )}
          {translate(linkKey)}
        </a>

        <style jsx>{linkStyles}</style>
      </div>
    );
  };

  const linksRenderer = () => {
    return Object.keys(links).map(linkRenderer);
  };

  return (
    <div className="footer-category-component">
      <div className="footer-category-image-and-title-warpper">
        {hasMondayLogoTitle && (
          <div className="monday-logo-wrapper">
            <PictureWithLinkComponent
              src={MONDAY_LOGO_SRC}
              className="footer-monday-logo-image"
              href={ROOT_LINK}
              alt="monday logo"
            />
          </div>
        )}
        {title && <h3 className="title">{translate(title)}</h3>}
      </div>
      <div className="links">{linksRenderer()}</div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(FooterCategoryComponent);
