import { withGenericConfig } from "contexts/generic-config-context";
import { withAbTests } from "contexts/ab-tests-context";
import { updateClusterFooterLinks } from "client/services/cluster-config-service/cluster-config-service";
import ShortFooter from "components/core/short-footer/short-footer";
import DefaultProps from "./default-props";
import FooterCategoryComponent from "./footer-category/footer-category-component";
import { componentStyles } from "./long-footer.scss";
import { SocialFooterLinks } from "/constants/links";
import FooterBottomBarComponent from "./footer-bottom-bar/footer-bottom-bar-component";
import { getFooterComponents } from "/services/localization/localization-service";

const Footer = (props) => {
  const { translate, localeId, abTests, pageClusterConfig, useProductMiniSiteConfig } = props;

  const renderShortFooter = () => {
    return <ShortFooter {...props} />;
  };

  const renderLongFooter = () => {
    const getFullFooterForLocalized = true;
    const footerComponents = getFooterComponents(localeId, abTests, getFullFooterForLocalized);

    if (pageClusterConfig) {
      updateClusterFooterLinks(pageClusterConfig, footerComponents, { useProductMiniSiteConfig });
    }

    const {
      MondayFooterLinks,
      OurTeamFooterLinksV2,
      MoreByMondayFooterLinks,
      UseCasesFooterLinksV2,
      ResourcesFooterLinks,
      ProductsFooterLinks,
      FeaturesFooterLinks
    } = footerComponents;

    return (
      <footer className="footer-container">
        <div className="footer-content-container">
          <nav className="footer-content" aria-label="Footer">
            <div className="categories-container">
              {MondayFooterLinks && (
                <div className="category-container">
                  <FooterCategoryComponent
                    title={translate(MondayFooterLinks.title)}
                    links={MondayFooterLinks.links}
                    hasMondayLogoTitle={MondayFooterLinks.hasMondayLogoTitle}
                  />
                </div>
              )}

              {FeaturesFooterLinks && (
                <div className="category-container features">
                  <FooterCategoryComponent
                    title={translate(FeaturesFooterLinks.title)}
                    links={FeaturesFooterLinks.links}
                  />
                </div>
              )}

              {ProductsFooterLinks && (
                <div className="category-container products">
                  <div className="categories-colmmn-wrapper">
                    <FooterCategoryComponent
                      type={ProductsFooterLinks.type}
                      title={translate(ProductsFooterLinks.title)}
                      links={ProductsFooterLinks.links}
                    />
                    <FooterCategoryComponent
                      type={MoreByMondayFooterLinks.type}
                      title={translate(MoreByMondayFooterLinks.title)}
                      links={MoreByMondayFooterLinks.links}
                    />
                  </div>
                </div>
              )}

              {UseCasesFooterLinksV2 && (
                <div className="category-container">
                  <FooterCategoryComponent
                    title={translate(UseCasesFooterLinksV2.title)}
                    links={UseCasesFooterLinksV2.links}
                  />
                </div>
              )}

              {OurTeamFooterLinksV2 && (
                <div className="category-container">
                  <FooterCategoryComponent
                    type={OurTeamFooterLinksV2.type}
                    title={translate(OurTeamFooterLinksV2.title)}
                    links={OurTeamFooterLinksV2.links}
                  />
                </div>
              )}

              {ResourcesFooterLinks && (
                <div className="category-container">
                  <FooterCategoryComponent
                    type={ResourcesFooterLinks.type}
                    title={translate(ResourcesFooterLinks.title)}
                    links={ResourcesFooterLinks.links}
                  />
                </div>
              )}
            </div>
          </nav>
        </div>

        <FooterBottomBarComponent SocialFooterLinks={SocialFooterLinks} />
      </footer>
    );
  };

  return (
    <>
      <div className="footer-desktop-wrapper">{renderLongFooter()}</div>

      <div className="footer-mobile-wrapper">{renderShortFooter()}</div>

      <style jsx>{componentStyles}</style>
    </>
  );
};

export default withAbTests(withGenericConfig(Footer));

Footer.defaultProps = DefaultProps;
