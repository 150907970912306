import {forwardRef, useEffect, useImperativeHandle, useState} from "react";
import classnames from "classnames";
import { componentStyles } from "./basic-header-component.scss";
import PictureWithLinkComponent from "components/core/picture-with-link/picture-with-link-component";
import { ROOT_LINK, MONDAY_LOGO_SRC } from "constants/links";
import { HEADER_POPUP_DATA } from "constants/header-popup";
import PictureComponent from "components/core/picture/picture-component";
import { POPUP_LINK_CLICKED } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";
import { withGenericConfig } from "/contexts/generic-config-context";

const BasicHeaderComponent = forwardRef((props, ref) => {
  const [isScrolled, setIsScrolled] = useState(false);
  const [shadowForced, setShadowForced] = useState(false);
  const {
    children,
    showMenu,
    imageSrc,
    imageHref,
    imageStyle,
    className,
    secondaryLogoObject,
    imageAlt,
    darkMode,
    logoPopup,
    translate,
    isMenuOpen,
    localeId
  } = props;

  useEffect(() => {
    window.addEventListener("scroll", handleScroll);
    return () => window.removeEventListener("scroll", handleScroll);
  }, []);

  const handleScroll = () => {
    setIsScrolled(window.scrollY > 0);
  };

  const forceShadow = () => {
    setShadowForced(true);
  };

  const unforceShadow = () => {
    setShadowForced(false);
  };

  const renderSecondaryLogoIfNeeded = () => {
    if (!secondaryLogoObject) return;
    return (
      <div className="secondary-logo-wrapper">
        <PictureWithLinkComponent
          src={secondaryLogoObject.src}
          className="base-header-monday-logo-image"
          href={secondaryLogoObject.url}
          alt={secondaryLogoObject.alt}
        />
      </div>
    );
  };

  const onPopupLinkClicked = (link) => {
    trackEvent(POPUP_LINK_CLICKED, { kind: link });
  };

  const getPopupData = (logoPopup) => {
    if (!logoPopup) return null;
    return HEADER_POPUP_DATA[logoPopup];
  };

  const renderPopupIfNeeded = () => {
    const logoPopupData = getPopupData(logoPopup);

    if (isMenuOpen || !logoPopupData) return null;

    const link = logoPopupData?.getLink(localeId);

    return (
      <div className={classnames("logo-popup", logoPopupData?.popupClassName)}>
        <div className="popup-text">
          <span
            className="text"
            dangerouslySetInnerHTML={{
              __html: translate(logoPopupData?.textKey)
            }}
          />
          <a
            href={link}
            className="link-text"
            target="_blank"
            rel="noreferrer"
            onClick={() => onPopupLinkClicked(link)}
          >
            {translate(logoPopupData?.ctaKey)}
          </a>
        </div>
        <div className="popup-gif">
          <PictureComponent
            src={logoPopupData?.gifSrc}
            pictureClass="popup-gif-image"
            specificImageStyle={{ height: "42px" }}
          />
        </div>
      </div>
    );
  };

  const isWithShadow = isScrolled || shadowForced;

  useImperativeHandle(ref, () => ({
    forceShadow,
    unforceShadow
  }));

  return (
    <div
      className={classnames("basic-header-component", className, {
        "with-secondary-logo": !!secondaryLogoObject,
        scrolled: isScrolled,
        "dark-mode": darkMode
      })}
    >
      <div className={classnames("basic-header-content-wrapper", { "with-shadow": isWithShadow })}>
        <div className="basic-header-content">
          <div className="main-logo-wrapper">
            <PictureWithLinkComponent
              src={imageSrc || MONDAY_LOGO_SRC}
              className="base-header-monday-logo-image"
              href={imageHref || ROOT_LINK}
              specificImageStyle={imageStyle}
              alt={imageAlt}
            />
            {renderPopupIfNeeded()}
          </div>
          {renderSecondaryLogoIfNeeded()}

          {showMenu && children}
        </div>
      </div>

      <style jsx>{componentStyles}</style>
    </div>
  );
});

BasicHeaderComponent.defaultProps = {
  showMenu: true,
  imageSrc: MONDAY_LOGO_SRC,
  imageHref: ROOT_LINK,
  imageAlt: "monday.com logo",
  secondaryLogoObject: null,
  isMenuOpen: false
};

export default withGenericConfig(BasicHeaderComponent);
