import { useState, useRef, useEffect } from "react";
import BasicHeaderComponent from "segments/desktop/header/layouts/components/basic-header/basic-header-component";
import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { withAbTests } from "/contexts/ab-tests-context";
import SecondaryHeaderDesktopComponent from "segments/desktop/header/layouts/base-header/secondary-header/secondary-header-desktop/secondary-header-desktop-component";
import {
  getMiniSiteUnifiedDesktopLogo,
  shouldHideGeneralPricingInMinisite,
  getClusterDesktopLogo,
  getMiniSiteLinks
} from "client/services/cluster-config-service/cluster-config-service";
import { withGenericConfig } from "/contexts/generic-config-context";

import { HEADER_SUBMENU_OPENED, HEADER_SUBMENU_CLOSED } from "/constants/bigbrain-event-types";
import {
  CUSTOMERS_MENU_ID,
  DESKTOP_HEADER_BASE_CLASS_NAME,
  PLATFORM_MENU_ID,
  RESOURCES_MENU_ID,
  SOLUTIONS_MENU_ID,
  TEAMS_MENU_ID
} from "segments/desktop/header/layouts/base-header/base-header-service";
import SignupPopupScreenComponent from "components/core/signups/signup-popup/signup-popup-screen-component";
import { SIGNUP_POPUP_CLOSED } from "constants/bigbrain-event-types.js";
import classnames from "classnames";
import { shouldHaveProductLearnMoreHeaderTest } from "services/ab-tests/acquisition-ab-test-service";
import { componentStyles } from "./base-header-desktop-component.scss";
import LinkItemComponent from "./link-item/link-item-component";
import HeaderConstants from "../constants";
import { trackEvent } from "/services/bigbrain-service";
import StructuredHeader from "./structured-header/structured-header";

const MENU_ID_TO_EVENT_KIND_MAP = {
  [SOLUTIONS_MENU_ID]: "Products",
  [TEAMS_MENU_ID]: "Teams",
  [PLATFORM_MENU_ID]: "Platform",
  [CUSTOMERS_MENU_ID]: "Success stories",
  [RESOURCES_MENU_ID]: "Resources"
};

const BaseHeaderDesktopComponent = (props) => {
  const [openedMenuId, setOpenedMenuId] = useState(null);
  const [showSignupPopup, setShowSignupPopup] = useState(false);
  const _scrollInfo = { scrollTopWhenStartedScrollingUp: 0, prevScrollTop: 0, scrollingDown: true };
  const _basicHeader = useRef(null);
  const _headerRef = useRef(null);
  const {
    isSecondaryMenuNeeded,
    buttonConfig,
    pageClusterConfig,
    useProductMiniSiteConfig,
    showMenu,
    hideLogin,
    showButton,
    logoHref,
    logoSrc,
    logoPopup,
    translate,
    isExistingAccountHeader,
    showLanguagePicker,
    abTests,
    shouldAddProductLearnMoreButtons,
    testTypeformContactSales,
    forceTypeformContactSalesExperience,
    showSuccessStoriesInHeader
  } = props;

  useEffect(() => {
    if (!openedMenuId) {
      removeClickOutsideEvent();
      return;
    }
    window.addEventListener("mousedown", handleClickOutsideMenu);
    return () => {
      removeClickOutsideEvent();
    };
  }, [openedMenuId]);

  useEffect(() => {
    if (isSecondaryMenuNeeded) {
      window.addEventListener("scroll", scrollListenerForSecondaryMenuUnification, {
        passive: true
      });
      return () => {
        window.removeEventListener("scroll", scrollListenerForSecondaryMenuUnification);
        removeClickOutsideEvent();
      };
    }
  }, []);

  const removeClickOutsideEvent = () => {
    window.removeEventListener("mousedown", handleClickOutsideMenu);
  };

  const handleClickOutsideMenu = (event) => {
    if (_headerRef?.current && !_headerRef.current.contains(event.target)) {
      closeLinkMenu();
    }
  };

  const scrollListenerForSecondaryMenuUnification = (event) => {
    {
      const header = document.querySelector(`.${DESKTOP_HEADER_BASE_CLASS_NAME}`);
      if (!header) {
        return;
      }

      let scrolledUpEnough = false;

      if (document.scrollingElement.scrollTop > _scrollInfo.prevScrollTop) {
        // scrolling down
        _scrollInfo.scrollingDown = true;
      } else {
        // scrolling up
        if (_scrollInfo.scrollingDown) {
          _scrollInfo.scrollTopWhenStartedScrollingUp = document.scrollingElement.scrollTop;
        }
        scrolledUpEnough =
          _scrollInfo.scrollTopWhenStartedScrollingUp - document.scrollingElement.scrollTop >
          HeaderConstants.SCROLL_BACK_UP_THRESHOLD;

        _scrollInfo.scrollingDown = false;
      }

      _scrollInfo.prevScrollTop = document.scrollingElement.scrollTop;

      const showUnifiedBanner =
        document.scrollingElement.scrollTop > HeaderConstants.SCROLL_PIXEL_THRESHOLD &&
        (_scrollInfo.scrollingDown || !scrolledUpEnough);

      if (document.scrollingElement.scrollTop > 0) {
        // shows the bottom menu border after scroll start
        header.classList.add("scroll-started");
      } else {
        header.classList.remove("scroll-started");
      }

      if (showUnifiedBanner) {
        header.classList.add("scrolled-threshold");
      } else {
        header.classList.remove("scrolled-threshold");
      }
    }
  };

  const openLinkMenu = (menuId) => {
    if (menuId === SOLUTIONS_MENU_ID) {
      shouldHaveProductLearnMoreHeaderTest(abTests, true);
    }

    setOpenedMenuId(menuId);
    trackEvent(HEADER_SUBMENU_OPENED, { kind: MENU_ID_TO_EVENT_KIND_MAP[menuId] ?? menuId });

    _basicHeader?.current?.forceShadow();
  };

  const closeLinkMenu = (e) => {
    if (!openedMenuId) return;

    setOpenedMenuId(null);

    trackEvent(HEADER_SUBMENU_CLOSED, { kind: MENU_ID_TO_EVENT_KIND_MAP[openedMenuId] ?? openedMenuId });
    _basicHeader?.current?.unforceShadow();
  };

  const getButtonConfig = () => {
    const { buttonType } = buttonConfig;

    let extraConfigs;

    switch (buttonType) {
      case SIGNUP_BUTTON_TYPE: {
        extraConfigs = { buttonSize: "xs" };
        break;
      }

      default: {
        extraConfigs = {};
        break;
      }
    }

    return { ...buttonConfig, ...extraConfigs };
  };

  const getUnifiedDesktopLogoIfExists = () => {
    if (!isSecondaryMenuNeeded) return null;
    return getMiniSiteUnifiedDesktopLogo(pageClusterConfig, { useProductMiniSiteConfig });
  };

  const shouldShowMainPricingLink = () => {
    if (!isSecondaryMenuNeeded) return true;
    return !shouldHideGeneralPricingInMinisite(pageClusterConfig, { useProductMiniSiteConfig });
  };

  const renderUnifiedLinks = () => {
    const links = getMiniSiteLinks(pageClusterConfig, { onlyUnifiedLinks: true, useProductMiniSiteConfig });

    return links.map((link) => (
      <div className="link-item-component-wrapper" key={link?.text}>
        <LinkItemComponent title={link?.text} url={link?.url} />
      </div>
    ));
  };

  const renderBaseHeader = () => {
    const baseHeaderProps = {
      showMenu,
      hideLogin,
      showButton,
      buttonConfig: getButtonConfig(),
      openedMenuId,
      openLinkMenu,
      closeLinkMenu,
      shouldShowMainPricingLink,
      isSecondaryMenuNeeded,
      renderUnifiedLinks,
      openSignupPopup,
      isExistingAccountHeader,
      showLanguagePicker,
      testTypeformContactSales,
      shouldAddProductLearnMoreButtons,
      forceTypeformContactSalesExperience,
      showSuccessStoriesInHeader
    };

    return <StructuredHeader {...baseHeaderProps} />;
  };

  const openSignupPopup = () => {
    setShowSignupPopup(true);
  };

  const closeSignupPopup = (data) => {
    trackEvent(SIGNUP_POPUP_CLOSED, data);
    setShowSignupPopup(false);
  };

  const getMainPageLogo = () => {
    if (pageClusterConfig && !isSecondaryMenuNeeded) {
      const clusterMainLogo = getClusterDesktopLogo(pageClusterConfig, { useProductMiniSiteConfig });
      if (clusterMainLogo) {
        return { logoHref: clusterMainLogo.url, logoSrc: clusterMainLogo.src, logoAltText: clusterMainLogo.alt };
      }
    }
    return { logoHref, logoSrc };
  };

  const unifiedDesktopLogo = getUnifiedDesktopLogoIfExists();

  const mainLogo = getMainPageLogo();

  return (
    <header className={classnames(DESKTOP_HEADER_BASE_CLASS_NAME)} ref={_headerRef}>
      <BasicHeaderComponent
        ref={_basicHeader}
        imageHref={mainLogo.logoHref}
        imageSrc={mainLogo.logoSrc}
        imageAlt={mainLogo.logoAltText}
        secondaryLogoObject={unifiedDesktopLogo}
        logoPopup={logoPopup}
        isMenuOpen={!!openedMenuId}
      >
        {renderBaseHeader()}
      </BasicHeaderComponent>

      {isSecondaryMenuNeeded && (
        <div className="secondary-header-wrapper">
          <SecondaryHeaderDesktopComponent />
        </div>
      )}
      {showSignupPopup && (
        <div className="signup-popup-wrapper">
          <SignupPopupScreenComponent translate={translate} closePopupAction={closeSignupPopup} />
        </div>
      )}
      <style jsx>{componentStyles}</style>
    </header>
  );
};

BaseHeaderDesktopComponent.defaultProps = {
  showMenu: true,
  showButton: true
};

export default withGenericConfig(withAbTests(BaseHeaderDesktopComponent));
