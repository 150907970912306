import { PureComponent } from "react";
import { withGenericConfig } from "contexts/generic-config-context";
import Colors from "/styles/colors";
import classnames from "classnames";
import { componentStyles } from "./badge-component.scss";

class BadgeComponent extends PureComponent {
  render() {
    const { translate, badgeText, badgeColorName, badgeTextColorName, badgeStrokeColorName } = this.props;

    return (
      <span
        className={classnames("core-badge-component", { "has-stroke": badgeStrokeColorName })}
        style={{
          backgroundColor: Colors[badgeColorName],
          color: Colors[badgeTextColorName],
          border: badgeStrokeColorName ? `1px solid ${Colors[badgeStrokeColorName]}` : "unset"
        }}
      >
        {badgeText || translate("header.menuItem.popular")}
        <style jsx>{componentStyles}</style>
      </span>
    );
  }
}

export default withGenericConfig(BadgeComponent);
