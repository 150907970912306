import { PureComponent } from "react";
import { ANDROID_APP_DOWNLOAD_LINK, IOS_APP_DOWNLOAD_LINK, LONG_FOOTER_BOTTOM_LINKS } from "/constants/links";
import classNames from "classnames";
import LanguagePickerComponent from "/components/core/language-picker/language-picker-component";
import { withGenericConfig } from "/contexts/generic-config-context";
import { DIALOG_DIRECTIONS } from "components/core/language-picker/language-picker-service";
import AccessibilityStatementLinkComponent from "components/accessibility/accessibility-statement-link/accessibility-statement-link-component";
import GooglePlayBig from "static/svg/social/google_play_big.svg";
import AppStoreBig from "static/svg/social/app_store_big.svg";
import PaletteIconsComponent from "./palette-icons/palette-icons-component";
import componentStyles from "./footer-bottom-bar-component.scss";
import linkTypesComponents from "./links/index";
import SecurityLogosComponent from "../security-logos/security-logos-component";
import AllRightsReservedComponent from "../all-rights-reserved/all-rights-reserved-component";

const FooterBottomBarComponent = (props) => {
  const { localeId, SocialFooterLinks, translate } = props;

  const renderLinks = () => {
    return LONG_FOOTER_BOTTOM_LINKS.map((linkData) => {
      const { title, componentName } = linkData;
      const ComponentToRender = linkTypesComponents[componentName];

      return (
        <div key={title} className={classNames("short-footer-link-component-wrapper")}>
          <ComponentToRender localeId={localeId} {...linkData} />
          <style jsx>{componentStyles}</style>
        </div>
      );
    });
  };

  const renderLanguagePicker = () => {
    return <LanguagePickerComponent dialogDirection={DIALOG_DIRECTIONS.ALIGN_TOP} />;
  };

  const renderAccessibilityStatementLink = () => {
    return <AccessibilityStatementLinkComponent />;
  };

  return (
    <div className="footer-bottom-bar-component">
      <div className="footer-bottom-bar-both-sides-wrapper">
        <div className="footer-bottom-bar-left-side">
          <div className="language-picker-and-security-logos-wrapper">
            <div className="language-picker-wrapper">{renderLanguagePicker()}</div>
            <div className="security-logos-wrapper">
              <SecurityLogosComponent />
            </div>
          </div>
          <div className="social-statement-and-links-wrapper">
            <div className="palette-icons-wrapper">
              <PaletteIconsComponent SocialFooterLinks={SocialFooterLinks} />
            </div>
            <div className="statement-and-links-wrapper">
              <div className="links-container">{renderLinks()}</div>
              <AllRightsReservedComponent />
            </div>
          </div>
        </div>
        <div className="footer-bottom-bar-right-side">
          <div className="app-store-icons">
            <a
              target="_blank"
              className="app-icon"
              href={ANDROID_APP_DOWNLOAD_LINK}
              title="Download the monday android App from Google Play"
            >
              <GooglePlayBig />
            </a>
            <a
              target="_blank"
              className="app-icon"
              href={IOS_APP_DOWNLOAD_LINK}
              title="Download the monday iOS App from the App Store"
            >
              <AppStoreBig />
            </a>
          </div>
          <div className="accessibility-statement-link-wrapper">{renderAccessibilityStatementLink()}</div>
        </div>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(FooterBottomBarComponent);
