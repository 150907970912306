import { PureComponent } from "react";
import { componentStyles } from "./base-header-menu-component.scss";
import HeaderMenuItemComponent from "./header-menu-item/header-menu-item-component";

export default class BaseHeaderMenuComponent extends PureComponent {
  renderHeaderMenuItems() {
    const { menuItems } = this.props;

    return menuItems.map((menuItem, index) => {
      return (
        <div key={index} className="header-menu-item-component-wrapper">
          <HeaderMenuItemComponent {...menuItem} />
        </div>
      );
    });
  }

  render() {
    return (
      <div className="base-header-menu-component">
        <div className="header-menu-items-wrapper">{this.renderHeaderMenuItems()}</div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}
