import { useCallback } from "react";
import { withGenericConfig } from "contexts/generic-config-context";
import classnames from "classnames";
import colors from "styles/colors";
import { HEADER_SUBMENU_LINK_CLICKED } from "/constants/bigbrain-event-types";
import { trackEvent } from "/services/bigbrain-service";
import TitleLine from "static/svg/header/header_subtitle_line.svg";

import MondayMarkupLanguageComponent from "client/components/system/monday-markup-language/monday-markup-language-component";
import { BACKGROUND_GRAY_COLOR_NAME, WHITE_COLOR_NAME } from "styles/color-consts";
import PictureComponent from "components/core/picture/picture-component";
import Button from "segments/desktop/core-components/button/button";
import { SECONDARY_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { componentStyles } from "./header-menu-section-component.scss";
import HeaderMenuSectionItemComponent from "../header-menu-section-item/header-menu-section-item-component";

const MAX_NUMBER_OF_ITEMS_IN_COLUMN_DEFAULT = 3;

const HeaderMenuSectionComponent = (props) => {
  const {
    menuSectionItems,
    maxNumberOfItemsInColumn,
    title,
    subtitle,
    seeMoreItem,
    translate,
    classname,
    titleLine,
    titleImage,
    titleCTA,
    titleUrl,
    menuId,
    id,
    withTitleUnderline
  } = props;

  const onButtonClicked = useCallback(
    (event, url, eventInfo) => {
      trackEvent(HEADER_SUBMENU_LINK_CLICKED, {
        kind: url,
        info1: eventInfo,
        info2: menuId,
        info3: id
      });

      event?.stopPropagation();
    },
    [id, menuId]
  );

  const getStyle = (menuSectionItem) => {
    const { backgroundColor, hoverBackgroundColor } = menuSectionItem;
    const backgroundColorValue = backgroundColor || colors[WHITE_COLOR_NAME];
    const hoverBackgroundColorValue = hoverBackgroundColor || colors[BACKGROUND_GRAY_COLOR_NAME];

    return {
      "--header-menu-section-item-background-color": backgroundColorValue,
      "--header-menu-section-item-hover-background-color": hoverBackgroundColorValue
    };
  };

  const renderHeaderMenuItems = useCallback(() => {
    return menuSectionItems.map((menuSectionItem) => {
      return <HeaderMenuSectionItemComponent {...menuSectionItem} menuId={menuId} sectionId={id} />;
    });
  }, [menuSectionItems]);

  const renderBtnFromConfig = useCallback(
    (btnConfig) => {
      if (!btnConfig) return null;
      const { title: btnText, buttonType = SECONDARY_BUTTON_TYPE, eventInfo, ...config } = btnConfig;

      const buttonConfig = {
        buttonText: translate(btnText),
        buttonType,
        onClick: (event) => onButtonClicked(event, config?.url, eventInfo),
        ...config
      };

      return <Button {...buttonConfig} />;
    },
    [onButtonClicked, translate]
  );

  const onTitleClick = (e) => {
    const isKeyboardEvent = e?.screenX === 0 && e?.screenY === 0;

    if (titleUrl) {
      trackEvent(HEADER_SUBMENU_LINK_CLICKED, {
        kind: titleUrl,
        info1: isKeyboardEvent ? "keyboard" : "mouse",
        info2: menuId,
        info3: id
      });
      window.location.href = titleUrl;
    }
  };

  return (
    <div className="header-menu-section-component">
      <div
        className={classnames("header-menu-section-title", {
          "with-underline": withTitleUnderline,
          "with-image-and-CTA": titleImage && titleCTA
        })}
        onClick={onTitleClick}
      >
        {(titleImage || titleCTA) && (
          <div className="header-menu-section-title-image-and-cta">
            {titleImage && <PictureComponent src={titleImage} className="header-menu-section-title-image" />}
            {renderBtnFromConfig(titleCTA)}
          </div>
        )}
        {titleLine && <TitleLine className="header-menu-section-title-line" />}
        <MondayMarkupLanguageComponent className="header-menu-section-title-text" text={translate(title)} />
      </div>
      {subtitle && (
        <div className="header-menu-section-subtitle">
          <MondayMarkupLanguageComponent text={translate(subtitle)} />
        </div>
      )}
      <div
        className={classnames("header-menu-section-items-wrapper", classname)}
        style={{ "--max-number-of-items-in-column": maxNumberOfItemsInColumn }}
      >
        {renderHeaderMenuItems()}
      </div>
      {seeMoreItem && <div className="header-menu-see-more-wrapper">{renderBtnFromConfig(seeMoreItem)}</div>}
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(HeaderMenuSectionComponent);

HeaderMenuSectionComponent.defaultProps = {
  maxNumberOfItemsInColumn: MAX_NUMBER_OF_ITEMS_IN_COLUMN_DEFAULT
};
