import HeaderMenuSectionItemComponent from "segments/desktop/header/layouts/base-header/base-header-desktop/header-menu/sections-header-menu/header-menu-section-item/header-menu-section-item-component";
import { componentStyles } from "./bottom-items-component.scss";

const BottomItemsComponent = (props) => {
  const { items, menuId } = props;
  return (
    <div className="bottom-items-component">
      <div className="bottom-items">
        {items.map((bottomItem, index) => (
          <>
            {index !== 0 && <div className="separator" />}
            <HeaderMenuSectionItemComponent {...bottomItem} menuId={menuId} />
          </>
        ))}
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default BottomItemsComponent;
