import FullStarIcon from "static/svg/header/customers/filled_star.svg";
import PartiallyFullStarIcon from "static/svg/header/customers/partially_filled_star.svg";
import G2Logo from "static/svg/header/customers/g2.svg";
import TrustRadiusLogo from "static/svg/header/customers/Trustradius.svg";
import { withGenericConfig } from "contexts/generic-config-context";
import { componentStyles } from "./social-proof-component.scss";

const SocialProofComponent = ({ translate }) => {
  return (
    <div className="social-proof-component">
      <span className="stars">
        <FullStarIcon />
        <FullStarIcon />
        <FullStarIcon />
        <FullStarIcon />
        <PartiallyFullStarIcon />
      </span>
      <span className="reviews-text">{translate("header.tabs.customers.section.bottomPart.socialProofText")}</span>
      <div className="logo-wrapper">
        <G2Logo height="19" width="19" />
      </div>
      <div className="logo-wrapper">
        <TrustRadiusLogo height="15" width="auto" />
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(SocialProofComponent);
