import classnames from "classnames";
import { withGenericConfig } from "contexts/generic-config-context";
import ExpandArrow from "static/svg/header/expand_arrow_1.svg";
import ArrowRight from "static/svg/arrow-right.svg";
import { trackEvent } from "services/bigbrain-service";
import {
  HEADER_LEFT_PANEL_ITEM_CLICKED,
  HEADER_LEFT_PANEL_SUBITEM_CLICKED,
  HEADER_LEFT_PANEL_SUBITEM_CONTENT_VIEWED
} from "constants/bigbrain-event-types";
import { generateOnKeyDownHandler } from "client/services/accessibility/accessibility-service";
import { useEffect } from "react";
import debounce from "lodash/debounce";
import usePrevious from "client/services/hooks/use-previous";
import HeaderMenuSectionItemComponent from "../../sections-header-menu/header-menu-section-item/header-menu-section-item-component";
import { componentStyles } from "./structured-header-menu-left-panel.scss";

const SUB_ITEM_HOVER_DEBOUNCE_RATE = 1000; // 1sec
const SUB_ITEM_CHANGED_BY = {
  DEFAULT: "default",
  USER: "user"
};

const subItemHoverAction = ({ menuItem, subMenuItem, changedBy }) => {
  trackEvent(HEADER_LEFT_PANEL_SUBITEM_CONTENT_VIEWED, { kind: subMenuItem.id, info1: menuItem.id, info3: changedBy });
};

const debounceOnHover = debounce(
  ({ menuItem, subMenuItem, changedBy }) => subItemHoverAction({ menuItem, subMenuItem, changedBy }),
  SUB_ITEM_HOVER_DEBOUNCE_RATE
);

const StructuredHeaderMenuLeftPanelComponent = (props) => {
  const {
    menu,
    menuId,
    innerMenuIndex,
    onItemClick,
    innerMenuSubIndex,
    setInnerMenuIndex,
    setInnerMenuSubIndex,
    translate,
    hasSubItems,
    itemsCollapsedState
  } = props;
  const { bottomItem } = menu;

  const prevInnerMenuSubIndex = usePrevious(innerMenuSubIndex);

  const onLeftPanelItemClick = ({ itemIndex }) => {
    trackEvent(HEADER_LEFT_PANEL_ITEM_CLICKED, {
      kind: menu.items[itemIndex].id,
      info2: menuId,
      info3: itemsCollapsedState[itemIndex]
    });
    onItemClick(itemIndex);
  };

  const onSubItemClick = ({ itemIndex, subItemIndex }) => {
    setInnerMenuSubIndex(subItemIndex);
    trackEvent(HEADER_LEFT_PANEL_SUBITEM_CLICKED, {
      kind: menu.items[itemIndex].subItems[subItemIndex].id,
      info2: menuId
    });
  };

  useEffect(() => {
    if (hasSubItems(innerMenuIndex)) {
      const changedBy = prevInnerMenuSubIndex === undefined ? SUB_ITEM_CHANGED_BY.DEFAULT : SUB_ITEM_CHANGED_BY.USER;
      debounceOnHover({
        menuItem: menu.items[innerMenuIndex],
        subMenuItem: menu.items[innerMenuIndex].subItems[innerMenuSubIndex],
        changedBy
      });
    }
  }, [innerMenuSubIndex, innerMenuIndex]);

  const onSubItemHover = ({ itemIndex, subItemIndex }) => {
    setInnerMenuIndex(itemIndex);
    setInnerMenuSubIndex(subItemIndex);
  };

  const renderSubItem = ({ itemIndex, subItem, subItemIndex }) => {
    const { icon, title, hasBetaTag } = subItem;
    const Icon = icon;
    return (
      <div
        className={classnames("inner-menu-item-subitem", {
          selected: itemIndex === innerMenuIndex && subItemIndex === innerMenuSubIndex
        })}
        onClick={() => {
          onSubItemClick({ itemIndex, subItemIndex });
        }}
        onMouseEnter={() => {
          onSubItemHover({ itemIndex, subItemIndex });
        }}
        onKeyPress={generateOnKeyDownHandler(() => onSubItemClick({ itemIndex, subItemIndex }))}
        tabIndex={itemIndex === innerMenuIndex ? 0 : -1}
      >
        <div className="inner-menu-item-subitem-title-and-icon">
          {icon && <Icon className={classnames("inner-menu-item-subitem-title-icon")} />}
          <span>{translate(title)}</span>
          {hasBetaTag && (
            <div className="inner-menu-item-subitem-tag">{translate("header.tabs.solutions.byProduct.dev.beta")}</div>
          )}
        </div>
        <div className="inner-menu-item-subitem-title-arrow">
          <ArrowRight className="arrow-icon" />
        </div>
      </div>
    );
  };

  const isItemArrowPointingDown = (itemIndex) => hasSubItems(itemIndex) && itemsCollapsedState[itemIndex];

  const isExpandableItem = (itemIndex) => {
    if (hasSubItems(itemIndex)) {
      return false;
    }

    return true;
  };

  const renderItem = ({ item, itemIndex }) => {
    const isItemSelected = itemIndex === innerMenuIndex;
    return (
      <div
        className={classnames("inner-menu-item", {
          selected: isItemSelected,
          collapsed: itemsCollapsedState[itemIndex]
        })}
      >
        <div
          className="inner-menu-item-title"
          onClick={() => onLeftPanelItemClick({ itemIndex })}
          onKeyPress={generateOnKeyDownHandler(() => onLeftPanelItemClick({ itemIndex }))}
          tabIndex={0}
        >
          {translate(item?.title)}
          {isExpandableItem(itemIndex) && (
            <ExpandArrow
              className={classnames("expand-arrow", { "down-direction": isItemArrowPointingDown(itemIndex) })}
            />
          )}
        </div>
        {hasSubItems(itemIndex) && (
          <div className={classnames("inner-menu-item-subitems")}>
            {item.subItems.map((subItem, subItemIndex) => renderSubItem({ itemIndex, subItem, subItemIndex }))}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="structured-header-menu-left-panel">
      <div className="structured-header-menu-left-panel-items">
        {menu.items.map((item, itemIndex) => renderItem({ item, itemIndex }))}
      </div>
      <div>{bottomItem && <HeaderMenuSectionItemComponent {...bottomItem} /* menuId={menuId} sectionId={id} */ />}</div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(StructuredHeaderMenuLeftPanelComponent);
