import css from "styled-jsx/css";
import Colors from "styles/colors";
import { DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

const borderBottomSize = "1px";
const transparentBorderBottom = `${borderBottomSize} solid transparent`;
const borderBottom = `${borderBottomSize} solid ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]}`;

export const componentStyles = css.global`
  .secondary-link-item-component {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding-top: ${borderBottomSize};
    padding-left: 16px;
    padding-right: 16px;
    text-decoration: none;
    color: ${Colors["asphalt"]};
    transition: color 200ms ease;
    font-weight: 300;
    font-size: 0.875rem;
    line-height: 24px;
    border-bottom: ${transparentBorderBottom};

    &.selected {
      color: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
      border-bottom: ${borderBottom};
    }

    &:hover {
      color: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
    }
  }
`;
