import css from "styled-jsx/css";
import Colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css.global`
  .structured-header-menu-bottom-part-component {
    display: flex;
    padding: 8px 0;
    border-top: ${`1px solid ${Colors[BACKGROUND_GRAY_COLOR_NAME]}`};
    .spacer {
      flex: 1;
    }
  }
`;
