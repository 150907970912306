import { PureComponent } from "react";
import { componentStyles } from "./header-menu-item-component.scss";
import { withGenericConfig } from "contexts/generic-config-context";
import BadgeComponent from "components/core/badge/badge-component";
import { HEADER_SUBMENU_LINK_CLICKED } from "/constants/bigbrain-event-types";
import RightArrow from "/static/svg/arrow-right2.svg";

class HeaderMenuItemComponent extends PureComponent {
  onClick = (e) => {
    const { url } = this.props;
    const isKeyboardEvent = e?.screenX === 0 && e?.screenY === 0;
    BigBrain("track", HEADER_SUBMENU_LINK_CLICKED, { kind: url, info1: isKeyboardEvent ? "keyboard" : "mouse" });
  };

  render() {
    const { icon: Icon, title, description, url, isPopular, translate, footer } = this.props;

    return (
      <a className="header-menu-item-component" href={url} onClick={this.onClick}>
        <div className="header-menu-item-content">
          <Icon className="menu-item-title-icon" />

          <div className="menu-item-text-wrapper">
            <div className="menu-item-title-wrapper">
              <span className="menu-item-title">{translate(title)}</span>
              {isPopular && (
                <div className="new-badge-component-wrapper">
                  <BadgeComponent />
                </div>
              )}
            </div>

            <span className="menu-item-description">{translate(description)}</span>

            {footer && (
              <span className="menu-item-footer">
                {translate(footer)} <RightArrow />
              </span>
            )}
          </div>
        </div>

        <style jsx>{componentStyles}</style>
      </a>
    );
  }
}

export default withGenericConfig(HeaderMenuItemComponent);
