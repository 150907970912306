import { PureComponent } from "react";
import { componentStyles } from "./security-logos-component.scss";
import { withGenericConfig } from "/contexts/generic-config-context";
import PictureWithLinkComponent from "components/core/picture-with-link/picture-with-link-component";
import { SecurityLogoLinks } from "/constants/links";

const ISO_LOGO_SRC = "/static/img/footer/iso-logo1.png";
const GDPR_LOGO_SRC = "/static/img/footer/gdpr-logo.png";
const SOC_LOGO_SRC = "/static/img/footer/soc-logo.png";
const HIPAA_LOGO_SRC = "/static/img/footer/hipaa-logo.png";

const ISO = "iso";
const SOC = "soc";
const GDPR = "gdpr";
const HIPAA = "hipaa";

class SecurityLogosComponent extends PureComponent {
  render() {
    return (
      <div className="security-logos-component">
        <div className="gdpr-logo-img-container" onClick={this.goToGdprPage}>
          <PictureWithLinkComponent src={GDPR_LOGO_SRC} alt="gdpr" href={SecurityLogoLinks[GDPR]} />
        </div>
        <div className="iso-logo-img-container">
          <PictureWithLinkComponent src={ISO_LOGO_SRC} alt="iso" href={SecurityLogoLinks[ISO]} />
        </div>
        <div className="soc-logo-img-container" onClick={this.goToSocPage}>
          <PictureWithLinkComponent src={SOC_LOGO_SRC} alt="soc" href={SecurityLogoLinks[SOC]} />
        </div>
        <div className="hipaa-logo-img-container" onClick={this.goToHipaaPage}>
          <PictureWithLinkComponent src={HIPAA_LOGO_SRC} alt="hipaa" href={SecurityLogoLinks[HIPAA]} />
        </div>
        <style jsx>{componentStyles}</style>
      </div>
    );
  }
}

export default withGenericConfig(SecurityLogosComponent);
