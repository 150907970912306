import { useState } from "react";
import {
  SectionsHeadersMenuItemsMapping,
  PRODUCTS_MENU_ID,
  USE_CASES_MENU_ID,
  FEATURES_MENU_ID,
  RESOURCES_MENU_ID,
  mobileProductsSectionMenuItems
} from "segments/desktop/header/layouts/base-header/base-header-service";
import { PRICING_PAGE_LINK, CONTACT_US_FROM_HEADER_PAGE_LINK, ROOT_LINK, LOGIN_PAGE_LINK } from "constants/links";
import { getMiniSitePricingPageLink } from "client/services/cluster-config-service/cluster-config-service";
import { TYPEFORM_UNHANDLED_EXCEPTION } from "constants/bigbrain-event-types";
import { withGenericConfig } from "/contexts/generic-config-context";
import { withAbTests } from "/contexts/ab-tests-context";
import "@typeform/embed/build/css/popup.css";
import { shouldGetContactSalesTypeformMobileTest } from "services/ab-tests/acquisition-ab-test-service";
import { handleClientException } from "client/services/exceptions-handling-service/exceptions-handling-service";
import { openContactSalesTypeForm, TYPEFORM_MOBILE_DEVICE_TYPE } from "constants/typeform-contact-sales";
import PictureComponent from "components/core/picture/picture-component";
import SecondaryButton from "segments/desktop/core-components/button/secondary-button/secondary-button";
import DownloadMobileAppComponent from "./download-mobile-app/download-mobile-app-component";
import LinkComponent from "./link/link-component";
import LinkWithSectionsMenuComponent from "./link-with-menu/link-with-sections-menu-component";
import { componentStyles } from "./header-menu-component.scss";

const HeaderMenuComponent = (props) => {
  const [openedMenuId, setOpenedMenuId] = useState(null);
  const {
    isMinisitePage,
    pageClusterConfig,
    useProductMiniSiteConfig,
    abTests,
    cloudfrontViewerCountry,
    testTypeformContactSales,
    translate,
    contactSalesTestMode
  } = props;

  const SMALL_MONDAY_LOGO = "https://dapulse-res.cloudinary.com/image/upload/mobile_menu/Monday.png";

  const openLinksMenu = (menuId) => {
    setOpenedMenuId(menuId);
  };

  const getPricingPageLink = () => {
    let minisitePricingPageLink = null;
    if (isMinisitePage) {
      minisitePricingPageLink = getMiniSitePricingPageLink(pageClusterConfig, { useProductMiniSiteConfig });
    }

    return minisitePricingPageLink || PRICING_PAGE_LINK;
  };

  const onContactSalesClicked = () => {
    if (testTypeformContactSales || shouldGetContactSalesTypeformMobileTest(abTests, true)) {
      try {
        openContactSalesTypeForm({
          country: cloudfrontViewerCountry,
          deviceType: TYPEFORM_MOBILE_DEVICE_TYPE,
          isTest: contactSalesTestMode
        });
      } catch (e) {
        const bbEventParams = { name: TYPEFORM_UNHANDLED_EXCEPTION };
        handleClientException({ e, bbEventParams });
        window.location = CONTACT_US_FROM_HEADER_PAGE_LINK;
      }
    }
  };

  const renderContactSalesMobileButton = () => {
    let buttonURLProp = {};
    if (!testTypeformContactSales && !shouldGetContactSalesTypeformMobileTest(abTests)) {
      buttonURLProp = { url: CONTACT_US_FROM_HEADER_PAGE_LINK };
    }
    return <LinkComponent title="header.contactSales" onClickFunc={onContactSalesClicked} {...buttonURLProp} />;
  };

  return (
    <div className="header-menu-mobile-component">
      <LinkWithSectionsMenuComponent
        id={PRODUCTS_MENU_ID}
        title="header.products"
        links={mobileProductsSectionMenuItems}
        openLinksMenu={openLinksMenu}
        isOpen={openedMenuId === PRODUCTS_MENU_ID}
      />
      <LinkWithSectionsMenuComponent
        id={USE_CASES_MENU_ID}
        title="header.useCases"
        links={SectionsHeadersMenuItemsMapping[USE_CASES_MENU_ID]}
        openLinksMenu={openLinksMenu}
        isOpen={openedMenuId === USE_CASES_MENU_ID}
      />
      <LinkWithSectionsMenuComponent
        id={FEATURES_MENU_ID}
        title="header.features"
        links={SectionsHeadersMenuItemsMapping[FEATURES_MENU_ID]}
        openLinksMenu={openLinksMenu}
        isOpen={openedMenuId === FEATURES_MENU_ID}
      />
      <LinkWithSectionsMenuComponent
        id={RESOURCES_MENU_ID}
        title="header.resources"
        links={SectionsHeadersMenuItemsMapping[RESOURCES_MENU_ID]}
        openLinksMenu={openLinksMenu}
        isOpen={openedMenuId === RESOURCES_MENU_ID}
      />
      <LinkComponent title="header.pricing" url={getPricingPageLink()} />

      {renderContactSalesMobileButton()}

      <LinkComponent title="header.logIn" url={LOGIN_PAGE_LINK} />

      <div className="download-mobile-app-component-wrapper">
        <DownloadMobileAppComponent />
      </div>
      <div className="monday-link-wrapper">
        <PictureComponent src={SMALL_MONDAY_LOGO} />
        <div className="monday-link-text-wrapper">
          <SecondaryButton buttonText={translate("header.menuItem.backToHomePage")} url={ROOT_LINK} />
        </div>
      </div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(withAbTests(HeaderMenuComponent));
