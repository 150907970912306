import css from "styled-jsx/css";
import Colors from "styles/colors";
import Constants from "../constants";

const BORDER_BOTTOM = `1px solid ${Colors["dark-marble"]}`;
const LINK_HEIGHT = `${Constants.LINK_HEIGHT}px`;

export const componentStyles = css.global`
  .link-mobile-component {
    height: ${LINK_HEIGHT};
    border-bottom: ${BORDER_BOTTOM};
    font-size: 1.125rem;
    color: ${Colors["text-gray"]};
    display: flex;
    align-items: center;
    flex-shrink: 0;
    text-decoration: none;
  }
`;
