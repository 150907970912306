import css from "styled-jsx/css";
import colors from "/styles/colors";
import { DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css`
  .footer-category-component {
    .footer-category-image-and-title-warpper {
      .monday-logo-wrapper {
        margin-bottom: 14px;
        :global(.footer-monday-logo-image) {
          height: 32px;
        }
      }
      .title {
        margin-top: 10px;
        margin-bottom: 24px;
        font-size: 0.875rem;
        font-weight: 500;
      }
    }
  }
`;

export const linkStyles = css`
  .link {
    line-height: 22px;
    &:not(:last-child) {
      margin-bottom: 16px;
    }

    &:hover {
      color: ${colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
    }

    a {
      text-decoration: none;
      color: inherit;
      display: flex;
      column-gap: 8px;
      align-items: flex-end;
      :global(.picture-component) {
        display: flex;
        :global(img) {
          height: 18px;
        }
      }
    }
  }
`;
