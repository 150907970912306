import { SIGNUP_BUTTON_TYPE } from "segments/desktop/core-components/button/button-consts";
import { MONDAY_LOGO_SRC, ROOT_LINK } from "/constants/links";

export default {
  showMenu: true,
  hideLogin: false,
  showButton: true,
  showLanguagePicker: false,
  logoHref: ROOT_LINK,
  logoSrc: MONDAY_LOGO_SRC,
  buttonConfig: {
    buttonType: SIGNUP_BUTTON_TYPE
  }
};
