import { useCallback } from "react";
import classnames from "classnames";
import colors from "styles/colors";
import HeaderMenuSectionComponent from "segments/desktop/header/layouts/base-header/base-header-desktop/header-menu/sections-header-menu/header-menu-section/header-menu-section-component.js";
import { withGenericConfig } from "contexts/generic-config-context";
import { componentStyles } from "./structured-header-menu-content.scss";

const StructuredHeaderMenuContentComponent = (props) => {
  const { menuId, id, content = {}, translate } = props;

  const { title: contentTitle, menuSections = [] } = content;

  const getStyle = () => {
    const backgroundColor = backgroundColor ? colors[backgroundColor] : "transparent";
    return {
      "--section-background-color": backgroundColor
    };
  };

  const renderHeaderMenuSections = useCallback(() => {
    return menuSections.map((menuSection, index) => {
      const { backgroundColor, classname, noSeparator, title } = menuSection;
      const isLastItem = menuSections.length - 1 === index;
      const hasBackgroundColor = !!backgroundColor;

      return (
        <>
          <div
            key={title}
            className={classnames("header-menu-section-component-wrapper", classname, {
              "has-background-color": hasBackgroundColor
            })}
            style={getStyle()}
          >
            <HeaderMenuSectionComponent {...menuSection} menuId={menuId} />
          </div>
          {!isLastItem && !noSeparator && <div className={classnames("sections-separator", classname)} />}
        </>
      );
    });
  }, [menuSections]);

  return (
    <div className="structured-header-menu-content">
      {contentTitle && <div className="structured-header-menu-content-title">{translate(contentTitle)}</div>}
      <div className="header-menu-sections-wrapper">{renderHeaderMenuSections()}</div>
      <style jsx>{componentStyles}</style>
    </div>
  );
};

export default withGenericConfig(StructuredHeaderMenuContentComponent);
