import css from "styled-jsx/css";
import Colors from "styles/colors";

export const componentStyles = css.global`
  .header-menu-item-component {
    display: flex;
    height: 100%;
    width: 100%;
    cursor: pointer;
    padding: 0px 10px;
    align-items: center;
    transition: background-color 200ms ease;
    text-decoration: none;
    color: ${Colors.black};

    &:hover {
      background-color: ${Colors["highlight-blue"]};
    }

    .header-menu-item-content {
      display: flex;

      .menu-item-title-icon {
        flex-shrink: 0;
        font-size: 0.875rem;
        margin-right: 16px;

        path {
          stroke: ${Colors.iris};
        }
      }

      .menu-item-text-wrapper {
        display: flex;
        flex-direction: column;

        .menu-item-title-wrapper {
          display: flex;

          .menu-item-title {
            font-weight: 400;
          }

          .new-badge-component-wrapper {
            margin-left: 8px;
          }
        }

        .menu-item-description {
          margin-top: 8px;
          font-size: 0.8125rem;
          line-height: 20px;
          color: ${Colors.asphalt};
        }

        .menu-item-footer {
          display: flex;
          align-items: center;
          margin-top: 16px;
          color: ${Colors.iris};
          font-size: 0.6875rem;
          position: absolute;
          top: 130px;

          svg {
            fill: currentColor;
            height: 10px;
            margin-left: 8px;
          }
        }
      }
    }
  }
`;
