import css from "styled-jsx/css";
import { MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH } from "constants/vp-sizes";
import Colors from "/styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME } from "styles/color-consts";
import {
  SEPERATOR_RIGHT_MARGIN,
  SEPERATOR_LEFT_MARGIN,
  SEPERATOR_LEFT_MARGIN_SMALL,
  SEPERATOR_RIGHT_MARGIN_SMALL
} from "./constants";
import { HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH } from "/constants/vp-sizes";

const SEPERATOR_RIGHT_MARGIN_PX = `${SEPERATOR_RIGHT_MARGIN}px`;
const SEPERATOR_RIGHT_MARGIN_SMALL_PX = `${SEPERATOR_RIGHT_MARGIN_SMALL}px`;
const SEPERATOR_LEFT_MARGIN_PX = `${SEPERATOR_LEFT_MARGIN}px`;
const SEPERATOR_LEFT_MARGIN_SMALL_PX = `${SEPERATOR_LEFT_MARGIN_SMALL}px`;

export const componentStyles = css.global`
  .sections-header-menu-component {
    width: 100%;
    background-color: rgba(255, 255, 255, 1);
    padding: 0;
    box-shadow: none;
    .header-menu-sections-wrapper {
      max-width: ${`${MAX_DESKTOP_DYNAMIC_TEMPLATE_WIDTH}px`};
      width: 100%;
      height: 100%;
      display: flex;
      margin: auto;
      padding: 0px 32px;
      .sections-separator {
        border-right: 1px solid;
        border-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
        margin: 32px ${SEPERATOR_RIGHT_MARGIN_PX} 36px ${SEPERATOR_LEFT_MARGIN_PX};
        &.products {
          margin-right: 80px;
        }
        &.mondayProducts {
          margin-right: 64px;
        }
      }
      .header-menu-section-component-wrapper {
        padding: 48px 0 0px;
        background-color: var(--section-background-color);
        min-height: 400px;
        &.has-background-color {
          flex: 1;
          padding-left: 54px;
          padding-top: 48px;
          padding-bottom: 32px;
        }
        &.products:first-of-type {
          .header-menu-section-title {
            font-size: 1.238rem;
            margin-bottom: 16px;
            margin-right: 80px;
            color: unset;
            font-weight: 500;
          }
          .header-menu-section-subtitle {
            font-size: 0.8125rem;
            line-height: 20px;
            margin-right: 50px;
            color: ${Colors.asphalt};
          }
        }
        &.products {
          width: 312px;
        }
        &.mondayProducts {
          .menu-item-title {
            opacity: 0.85;
          }
          padding-top: 30px;
          .header-menu-section-items-wrapper.mondayProducts {
            margin-top: 0;
          }
        }
      }
    }
  }

  @media (max-width: ${HEADER_DESKTOP_MENU_NARROW_SCREEN_MAX_WIDTH}) {
    .sections-header-menu-component {
      .header-menu-sections-wrapper {
        .sections-separator {
          margin-right: ${SEPERATOR_RIGHT_MARGIN_SMALL_PX};
          margin-left: ${SEPERATOR_LEFT_MARGIN_SMALL_PX};
        }
      }
    }
  }
`;
