import css from "styled-jsx/css";
import Colors from "styles/colors";
import Constants from "../constants";

const BORDER_BOTTOM = `1px solid ${Colors["dark-marble"]}`;
const MENU_ITEM_TITLE_HEIGHT = `${Constants.MENU_ITEM_TITLE_HEIGHT}px`;
const MENU_ITEM_LINK_HEIGHT = `${Constants.MENU_ITEM_LINK_HEIGHT}px`;
const SECTION_BORDER_BOTTOM = `1px solid ${Colors["riverstone"]}`;

export const componentStyles = css.global`
  .link-with-menu-mobile-component {
    border-bottom: ${BORDER_BOTTOM};
    overflow: hidden;
    transition: height 400ms ease;
    flex-shrink: 0;

    &.is-open {
      .link-with-menu-title-wrapper {
        .link-with-menu-title-text {
          color: ${Colors["iris"]};
        }

        .link-with-menu-title-icon {
          transform: rotate(180deg);

          path {
            fill: ${Colors["iris"]};
          }
        }
      }
    }

    .link-with-menu-title-wrapper {
      display: flex;
      align-items: center;
      font-size: 1.125rem;
      height: ${MENU_ITEM_TITLE_HEIGHT};
      cursor: pointer;

      .link-with-menu-title-text {
        color: ${Colors["text-gray"]};
        margin-right: 8px;
      }

      .link-with-menu-title-icon {
        height: 10px;
        width: 10px;
        transition: transform 400ms ease;
      }
    }

    .link-with-menu-links-wrapper {
      display: flex;
      flex-direction: column;
      transition: height 400ms ease;

      .section-wrapper {
        margin: 0 0 32px 12px;
      }

      .section-link {
        font-size: 0.875rem;
        color: ${Colors["text-gray"]};
        font-weight: bold;
        border-bottom: ${SECTION_BORDER_BOTTOM};
        padding: 16px 0;
      }

      .header-link {
        height: ${MENU_ITEM_LINK_HEIGHT};
        display: flex;
        align-items: center;
        font-size: 0.875rem;
        color: ${Colors["tuna"]};
        text-decoration: none;

        .new-badge-component-wrapper {
          margin-left: 8px;
        }

        &.subsection-link {
          height: 48px;
          display: flex;
          gap: 16px;
        }
      }
    }
  }
`;
