import { useEffect, useRef, useState } from "react";
import classnames from "classnames";
import { withGenericConfig } from "/contexts/generic-config-context";
import SecondaryLinkItemComponent from "segments/desktop/header/layouts/base-header/secondary-header/secondary-link-item/secondary-link-item-component";
import { getMiniSiteLinks } from "client/services/cluster-config-service/cluster-config-service";
import { isSameUrlWithoutQueryParams } from "utils/url";
import { withRouter } from "next/router";
import { isNodeFullyInView } from "services/dom-utils-service";
import { componentStyles } from "./secondary-header-mobile-component.scss";

const SCROLL_TRESHOLD = 10;
const INITIAL_SCROLL_FOR_OUTER_VIEWPORT_LINK = 64;

const SecondaryHeaderMobileComponent = (props) => {
  const [isScrollEnded, setIsScrollEnded] = useState(false);
  const selectedLinkRef = useRef(null);
  const linksWrapperRef = useRef(null);
  const { pageClusterConfig, router, useProductMiniSiteConfig } = props;

  const [linksWrapperScrollWidth, setLinksWrapperScrollWidth] = useState(null);
  const [linksWrapperOffsetWidth, setLinksWrapperOffsetWidth] = useState(null);

  useEffect(() => {
    if (selectedLinkRef?.current) {
      isNodeFullyInView(selectedLinkRef?.current).then((inView) => {
        if (!inView) {
          selectedLinkRef.current.scrollIntoView(true);
          linksWrapperRef.current.scrollLeft += INITIAL_SCROLL_FOR_OUTER_VIEWPORT_LINK;
        }
      });
    }

    linksWrapperRef.current.addEventListener("scroll", scrollListenerForSecondaryMenuRemoveFade, {
      passive: true
    });
  }, []);

  useEffect(() => {
    setLinksWrapperScrollWidth(linksWrapperRef.current?.scrollWidth);
    setLinksWrapperOffsetWidth(linksWrapperRef.current?.offsetWidth);
  }, [linksWrapperRef]);

  const scrollListenerForSecondaryMenuRemoveFade = () => {
    const scrollLeft = linksWrapperRef.current.scrollLeft;

    if (scrollLeft + SCROLL_TRESHOLD >= linksWrapperScrollWidth - linksWrapperOffsetWidth) {
      setIsScrollEnded(true);
    } else if (isScrollEnded) {
      setIsScrollEnded(false);
    }
  };

  const scrollToLinkItemOnClick = (e) => {
    e.target.scrollIntoView(true);
  };

  const renderLinks = () => {
    const links = getMiniSiteLinks(pageClusterConfig, { useProductMiniSiteConfig });

    return links.map((link, index) => {
      const isSelected = isSameUrlWithoutQueryParams(link?.url, router?.asPath);
      return (
        <div
          className="secondary-link-item-wrapper"
          key={index}
          ref={isSelected ? selectedLinkRef : null}
          onClick={scrollToLinkItemOnClick}
        >
          <SecondaryLinkItemComponent url={link?.url} title={link?.text} isSelected={isSelected} />
        </div>
      );
    });
  };

  return (
    <nav
      aria-label="Secondary"
      className={classnames("secondary-header-mobile-component", { "scroll-end": isScrollEnded })}
    >
      <div className="links-wrapper" ref={linksWrapperRef}>
        {renderLinks()}
      </div>
      <style jsx>{componentStyles}</style>
    </nav>
  );
};

export default withRouter(withGenericConfig(SecondaryHeaderMobileComponent));
