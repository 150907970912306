import css from "styled-jsx/css";
import Colors from "styles/colors";
import { BACKGROUND_GRAY_COLOR_NAME, DARK_WORK_OS_IRIS_COLOR_NAME } from "styles/color-consts";

export const componentStyles = css.global`
  .link-item-with-menu-component {
    cursor: pointer;
    display: flex;
    align-items: center;
    padding: 8px 10px;
    color: ${Colors["text-gray"]};
    transition: color 200ms ease;
    border-radius: 8px;
    &:hover {
      background-color: ${Colors[BACKGROUND_GRAY_COLOR_NAME]};
    }
    &.is-open,
    &:hover {
      color: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};

      .link-item-with-menu-icon {
        path {
          fill: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
          stroke: ${Colors[DARK_WORK_OS_IRIS_COLOR_NAME]};
        }
      }
    }

    &.is-open {
      .link-item-with-menu-icon {
        transform: rotate(180deg);
      }
    }

    .link-item-with-menu-title {
      font-size: 0.875rem;
      margin-right: 4px;
    }

    .link-item-with-menu-icon {
      height: 7px;
      width: 10px;
      transition: transform 200ms ease;

      path {
        fill: ${Colors.mud};
        stroke: ${Colors.mud};
      }
    }
  }
`;
