import { componentStyles } from "./footer-bottom-bar-link-component.scss";
import { withGenericConfig } from "contexts/generic-config-context";

const FooterBottomBarLink = (props) => {
  const { title, url, translate } = props;

  return (
    <a className="footer-bottom-bar-link-component" href={url}>
      {translate(title)}

      <style jsx>{componentStyles}</style>
    </a>
  );
};

export default withGenericConfig(FooterBottomBarLink);
